<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <p class="m-0">Payment Method</p>
      <div class="mt-4">
        <div class="card mb-3" style="cursor: pointer;" v-for="(payment_method, index) in arr_payment_method" :key="index" @click="onPaymentMethodClicked(index)">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <img :src="payment_method.url_image" class="rounded-circle" style="height: 3rem; width: 3rem;">
                <p class="m-0 ml-3">{{ payment_method.name }}</p>
              </div>
              <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <DetailPayment
        :registration_data="registration_data"
        :arr_player="registration_data.arr_player"
        :arr_match="registration_data.arr_match"
        :competition="registration_data.competition"
        :category="registration_data.team_category"/>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import NoImage from '@/assets/no_image.png';
import DetailPayment from '@/pages/registration/component/detail_payment';

export default {
  components: {
    'DetailPayment': DetailPayment,
  },
  props: ['registration_data', 'before_index', 'index', 'is_submit', ],
  data(){
    return{
      base: null,
      arr_payment_method: [],
      selected_payment_method: {},
    }
  },
  watch: {
    is_submit(val){
      if(val)
        this.validation()
    }
  },
  created(){
    this.base = new Base()

    this.get_payment_method()
  },
  methods: {
    async get_payment_method(){
      var response = await this.base.request(this.base.url_api + "/payment-method/all?is_active=1")

      if(response != null){
        if(response.status === "success"){
          for(let payment_method of response.data){
              payment_method.url_image = payment_method.file_name != null ? this.base.host + "/media/payment-method?file_name=" + payment_method.file_name : NoImage
          }
          this.arr_payment_method = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    onPaymentMethodClicked(index){
      this.selected_payment_method = this.arr_payment_method[index]
      this.$emit('onForceSubmit')
    },
    validation(){
      this.$emit('onValidation', '', {
        payment_method: this.selected_payment_method,
      })
    },
  }
}
</script>

<style lang="scss">
</style>
