<template>
  <div class="">
    <div class="card rounded-0">
      <div class="card-body mb-0">
        <div class="row">                                            
          <div class="col-12 align-self-center">
            <div class="">
              <h4 class="m-0 header-title">{{ momentNow.format('DD MMMM YYYY') }}</h4>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end card-body-->
    </div>

    <div class="card bg-primary rounded-0">
      <div class="card-body mb-0 text-white">
        <h5 class="m-0">{{ current_event.name != null ? current_event.name + ' is currently on Going' : 'No Event Currently on Going' }} </h5>
      </div><!--end card-body-->
    </div>

    <div class="row">
      <div class="col-12 col-sm-4">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-right" style="line-height: 1;">
              <i class="mdi mdi-24px mdi-account-check"></i>
            </div> 
            <span class="badge badge-primary">Registered Player</span>
            <h3 class="font-weight-bold">{{ total_registered_player }}</h3>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-right" style="line-height: 1;">
              <i class="mdi mdi-24px mdi-account-group"></i>
            </div> 
            <span class="badge badge-primary">Registered Team</span>
            <h3 class="font-weight-bold">{{ total_registered_team }}</h3>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-right" style="line-height: 1;">
              <i class="mdi mdi-24px mdi-invoice-clock"></i>
            </div> 
            <span class="badge badge-primary">Invoice Unpaid</span>
            <h3 class="font-weight-bold">{{ total_invoice_unpaid }}</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-3">
      <div class="card-body mb-0">
        <div class="row">                                            
          <div class="col-12 align-self-center">
            <div class="">
              <h4 class="mt-0 mb-4 header-title">Team Registered</h4>

              <div class="" style="display: grid; gap: 1rem; grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));" >
                <div class="card border mb-0" v-for="(team, index) in arr_team" :key="index">
                  <div class="card-body d-flex flex-row align-items-center" style="gap: .5rem">
                    <img :src="team.url_image" style="width: 5rem; height: 5rem; border-radius: 5rem; object-fit: cover; object-position: center">
                    <div class="d-flex flex-column" style="gap: .25rem;">
                      <h5 class="m-0 font-weight-bold" style="line-height: 1">{{ team.team_name }}</h5>
                      <p class="m-0 font-weight-light" style="line-height: 1">{{ team.event_category_sport_category.event_category_sport.category_sport.name }}</p>
                      <p class="m-0 font-weight-light" style="line-height: 1">{{ team.event_category_sport_category.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

export default {
  components: {
  },
  data(){
    return{
      base: null,
      arr_team: [],
      current_event: {},
      total_registered_player: 0,
      total_registered_team: 0,
      total_invoice_unpaid: 0,
      momentNow: momentTZ(),
    }
  },
  async created(){
    this.base = new Base()

    this.base.save_current_page('Dashboard', true, 'mdi mdi-home')
    this.get_home_data()
  },
  methods: {
    onRegisterClicked(index){
      this.selected_competition = this.arr_competition[index]
      $('#registrationModal').modal('show')
    },
    async get_home_data(){
      var response = await this.base.request(this.base.url_api + "/home")
      this.isLoading = false

      if(response != null){
        if(response.status === "success"){
          this.total_registered_player = response.total_registered_player
          this.total_registered_team = response.total_registered_team
          this.total_invoice_unpaid = response.total_invoice_unpaid
          this.arr_team = response.arr_team
          this.current_event = response.current_event
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
.competition-status{
  right: 1rem; 
  top: 1rem;
  border-radius: .3rem;
}
.competition-style-open{
  background-color: $green2;
  color: $green1; 
}
.competition-style-closed{
  background-color: $red2;
  color: $red1; 
}
.competition-style-soon{
  background-color: $yellow2;
  color: $yellow1; 
}
.card-team-container{
  flex: 1 1 300px
}
</style>
