<template>
  <div class="">
    <CompetitionHeader :competition="registration_data.competition" v-if="registration_data.competition != null" />
    <h4>Input Team Information</h4>

    <div class="row align-items-center mb-3">
      <div class="col-md-3">
        <p class="mb-2 mb-md-0">{{ $t('competition_category') }}</p>
      </div>
      <div class="col">
        <select v-model="team_category_id" class="form-control">
          <option value="">{{ $t('select_category') }}</option>
					<option v-for="(category, index) in arr_category" :key="index" :value="category.id"
						:disabled="category.is_registration_open == 0 || category.total_registration_success >= category.total_max_registration">
							{{ category.name }} ({{
								category.is_registration_open == 0 ? 'Closed' : (
									category.total_registration_success >= category.total_max_registration ? 'Full' :
									category.total_registration_success + ' of ' + category.total_max_registration
								)
							}})
					</option>
        </select>
      </div>
    </div>

    <div class="row align-items-center mb-3" v-show="selected_category.display_team == 'team'">
      <div class="col-md-3">
        <p class="mb-2 mb-md-0">{{ $t('team_name') }}</p>
      </div>
      <div class="col">
        <input type="text" v-model="team_name" class="form-control">
      </div>
    </div>
    <div class="row align-items-center mb-3" v-show="selected_category.display_team == 'team'">
      <div class="col-md-3">
        <p class="mb-2 mb-md-0">{{ $t('Team Logo') }}</p>
      </div>
      <div class="col">
        <CustomImagePicker :image_data1="image_data" @onResponse="(image_data) => onImageResponse(image_data)" />
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-md-3">
        <p class="mb-2 mb-md-0">{{ $t('jersey_team') }}</p>
      </div>
      <div class="col">
        <input type="text" v-model="jersey_team" class="form-control">
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-md-3">
        <p class="mb-2 mb-md-0">{{ $t('other_jersey_team') }}</p>
      </div>
      <div class="col">
        <input type="text" v-model="other_jersey_team" class="form-control">
      </div>
    </div>

    <div class="breakline"></div>

    <div class="border rounded-lg p-3 p-sm-4 mb-4 procedure-container">
      <p class="mb-3 font-weight-bold">{{ $t('registration_procedure') }}</p>

      <div class="row">

        <div class="col-12 col-md-6">
          <div class="mt-3 d-flex flex-column" style="gap: 1rem" v-for="(procedure, index) in arr_procedure_left" :key="index">
            <div class="d-flex align-items-baseline" style="gap: 1rem">
              <p class="m-0 border rounded-lg d-flex align-items-center justify-content-center w-3rem h-3rem bg-white">{{ index + 1 }}</p>
              <div class="m-0" v-html="procedure.content"></div>
            </div>
            <div v-for="(level2, index1) in procedure.level2" :key="index1" class="d-flex align-items-baseline ml-4" style="gap: 1rem">
              <p class="m-0 border rounded-lg d-flex align-items-center justify-content-center w-3rem h-3rem bg-white">{{ index + 1 }}.{{ index1 + 1 }}</p>
              <div class="m-0" v-html="level2.content"></div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="mt-3 d-flex flex-column" style="gap: 1rem" v-for="(procedure, index) in arr_procedure_right" :key="index">
            <div class="d-flex align-items-baseline" style="gap: 1rem">
              <p class="m-0 border rounded-lg d-flex align-items-center justify-content-center w-3rem h-3rem bg-white ">{{ arr_procedure_left.length + index + 1 }}</p>
              <div class="m-0" v-html="procedure.content"></div>
              <div class="mb-2 mb-md-0 procedure-content ml-3" v-html="procedure.content"></div>
            </div>
            <div v-for="(level2, index1) in procedure.level2" :key="index1" class="d-flex align-items-baseline ml-4" style="gap: 1rem">
              <p class="m-0 border rounded-lg d-flex align-items-center justify-content-center w-3rem h-3rem bg-white">{{ arr_procedure_left.length + index + 1 }}.{{ index1 + 1 }}</p>
              <div class="m-0" v-html="level2.content"></div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import $ from 'jquery'
import Base from '@/utils/base';

import CompetitionHeader from '@/pages/registration/component/competition_header';
import CustomImagePicker from '@/layout/custom_imagepicker';

export default {
  components: {
    'CompetitionHeader': CompetitionHeader,
    'CustomImagePicker': CustomImagePicker,
  },
  props: ['registration_data', 'before_index', 'index', 'is_submit',],
  data() {
    return {
      base: null,
      arr_category: [],
      arr_procedure: [],
      team_name: '',
      team_category_id: '',
      selected_category: {},
      jersey_team: '',
      other_jersey_team: '',
      image_data: {},
    }
  },
  computed: {
    arr_procedure_left() {
      var arr = []
      for (let x in this.arr_procedure) {
        if (x <= this.arr_procedure.length / 2)
          arr.push(this.arr_procedure[x])
      }
      return arr
    },
    arr_procedure_right() {
      var arr = []
      for (let x in this.arr_procedure) {
        if (x > this.arr_procedure.length / 2)
          arr.push(this.arr_procedure[x])
      }
      return arr
    },
  },
  watch: {
    before_index(val) {
      if (val == this.index)
        this.validation()
    },
    async registration_data(val) {
      if (val.competition != null){
				this.fill_data()
      }
    },
    team_category_id(val){
      var selected_category = {}
      for(let category of this.arr_category){
        if(category.id == val){
          selected_category = category
          break
        }
      }
      this.selected_category = selected_category
    }
  },
  async created() {
    this.base = new Base()

    this.get_procedure()
		await this.get_category()
		this.fill_data()
  },
  methods: {
		fill_data(){
			this.team_name = this.registration_data.team_name
			this.team_category_id = this.registration_data.team_category_id
			this.jersey_team = this.registration_data.jersey_team
			this.other_jersey_team = this.registration_data.other_jersey_team
			this.image_data = this.registration_data.image_data
		},
    onImageResponse(image_data) {
      this.image_data = image_data
    },
    async get_category() {
      var response = await this.base.request(this.base.url_api + "/event/category-sport/category/all?event_category_sport_id=" + this.registration_data.competition.id)

      if (response != null) {
        if (response.status === "success") {
          this.arr_category = response.data
        }
        // else
        //   this.base.show_error(response.message)
      }
      // else
      //   console.log(this.$t('server_error'))
    },
    async get_procedure() {
      var response = await this.base.request(this.base.url_api + "/registration/procedure/all")

      if (response != null) {
        if (response.status === "success") {
          this.arr_procedure = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async validation() {
      var message = ''
      if (this.team_category_id == null || this.team_category_id == '')
        message = 'Team Category is Empty'
      else if (this.selected_category.display_team == 'team' && (this.team_name == null || this.team_name == ''))
        message = 'Team Name is Empty'
      else if (this.jersey_team == null || this.jersey_team == '')
        message = 'The uniform color should be filled in'
      else {
        $('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/event/registration", 'post', {
          event_category_sport_category: this.selected_category,
          team_name: this.team_name,
          city: this.registration_data.city,
          jersey_team: this.jersey_team,
          other_jersey_team: this.other_jersey_team,
          image_data: this.image_data,
        })
        setTimeout(() => {
          $('#please_wait_modal').modal('hide')
        }, 500)

        if (response != null) {
          if (response.status != "success")
            message = response.message
            // this.base.show_error(response.message)
        }
        else
          message = ' '
      }

      if(message == ""){
        for(let player of response.data.player){
          player.image_data = {
            image_display: player.url_image
          }
        }

        for(let coach of response.data.coach){
          coach.image_data = {
            image_display: coach.url_image
          }
        }
      }
      this.$emit('onValidation', message, message == '' ? {
        team_name: this.team_name,
        team_category: this.selected_category,
        team_category_id: this.team_category_id,
        jersey_team: this.jersey_team,
        other_jersey_team: this.other_jersey_team,
        image_data: {
          image_display: response.data.url_image,
        },
        id: response.data.id,
        arr_player: response.data.player,
        arr_coach: response.data.coach,
        arr_order: response.data.order,
      } : {})
    },
  }
}
</script>

<style lang="scss">
.procedure-content * {
  margin: 0;
}

.procedure-container{
  background-color: $gray-100;
}

.breakline {
  border-bottom: 1px solid $gray-300;
  margin: 1.5rem 0;
}

.w-3rem {
  min-width: 3rem;
}
.h-3rem {
  height: 3rem;
}
</style>
