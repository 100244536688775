<template>
  <div class="">
    <p class="m-0">{{ $t('summary_payment') }}</p>

    <div class="card mt-4">
      <div class="card-body">
        <div>
          <p class="m-0 font-weight-bold">{{ $t('sport_category') }}</p>
          <p class="m-0">{{ competition != null ? competition.category_sport.name : '' }}</p>
          <p class="m-0">{{ competition != null && competition.event.school_registration_moment != null ? competition.event.school_registration_moment.format(base.date_format) : '' }}</p>
        </div>

        <div class="mt-4" v-if="registration_data.arr_team != null || (registration_data.arr_team == null && (registration_data.arr_order == null || registration_data.arr_order.length == 0))">
          <p class="m-0 font-weight-bold">Registered Team</p>
          <div>
            <div>
              <div class="d-flex justify-content-between">
                <p class="m-0">1 Team {{ registration_data.team_name }}</p>
                <p class="m-0">Rp. {{ category != null ? category.fee_registration.toLocaleString(base.locale_string) : '0' }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <p class="m-0 font-weight-bold">Registered Match</p>
          <div>
            <div v-if="arr_payment_match.length > 0">
              <div class="d-flex justify-content-between" v-for="(match, index) in arr_payment_match" :key="index">
                <p class="m-0">Match #{{ match.name }} {{ match.status_order != null ? '(' + match.status_order + ')' : '' }}</p>
                <p class="m-0">Rp. {{ category != null ? category.fee_per_match.toLocaleString(base.locale_string) : '0' }}</p>
              </div>
            </div>
            <div class="d-flex justify-content-center" v-else>
              <p class="m-0">{{ $t('no_data_found') }}</p>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div class="d-flex justify-content-between">
            <p class="m-0">{{ $t('subtotal') }}</p>
            <p class="m-0">Rp. {{ subtotal.toLocaleString(base.locale_string) }}</p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="m-0">{{ $t('service_charge') }}</p>
            <p class="m-0">Rp. {{ admin_fee.toLocaleString(base.locale_string) }}</p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="m-0 font-weight-bold">{{ $t('total') }}</p>
            <p class="m-0 text-primary">Rp. {{ total.toLocaleString(base.locale_string) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

export default {
  components: {
  },
  props: ['arr_player', 'arr_match', 'competition', 'category', 'registration_data', 'type', ],
  data(){
    return{
      base: null,
      arr_payment_player: [],
      arr_payment_match: [],
      subtotal: 0,
      admin_fee: 0,
      total: 0,
    }
  },
  watch: {
    arr_player: {
      handler: function() {
        this.manage_payment_player()
      },
      deep: true,
    },
    arr_payment_player: {
      handler: function() {
        this.calculate_total()
      },
      deep: true,
    },
    category(){
      this.calculate_total()
    },
    // arr_match(){
    //   this.manage_payment_match()
    // },
    arr_match: {
      handler: function(val) {
        console.log(val)
        this.manage_payment_match()
      },
      deep: true,
    },
    arr_payment_match: {
      handler: function() {
        this.calculate_total()
      },
      deep: true,
    },
  },
  async created(){
    this.base = new Base()

    this.get_admin_fee()
    this.manage_payment_player()
  },
  methods: {
    async get_admin_fee(){
      var response = await this.base.request(this.base.url_api + "/setting?key=service_charge")

      if(response != null){
        if(response.status === "success"){
          this.admin_fee = this.base.str_to_double(response.data.value)
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    calculate_total(){
      if(this.category != null){
        var subtotal = 0
      
        if(this.registration_data.arr_order == null || this.registration_data.arr_order.length == 0)
          subtotal += this.category.fee_registration
        subtotal += this.arr_payment_match.length * this.category.fee_per_match

        this.subtotal = subtotal
        this.total = subtotal + this.admin_fee
      }
    },
    manage_payment_player(){
      if(this.arr_player != null){
        var arr = []
        for(let player of this.arr_player)
          arr.push(player)
        this.arr_payment_player = arr
      }
    },
    manage_payment_match(){
      if(this.arr_match != null){
        var arr = []
        for(let match of this.arr_match){
          if(match.is_active && match.allow_clicked)
            arr.push(match)
        }
        this.arr_payment_match = arr
      }
    },
  }
}
</script>

<style lang="scss">
</style>
