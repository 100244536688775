<template>
  <div>
    <!-- <img src="@/assets/background_schedule.png" class="position-absolute" style="right: 0; z-index: -1;"/> -->
    <navbar :profile_data="profile_data"/>
    <topbar :profile_data="profile_data"/>
    <div class="page-wrapper">
      <div class="page-wrapper-inner">
        <div v-if="!this.$route.path.match(/\/registration*/g)">
          <SideBar :profile_data="profile_data"/>
        </div>
        <div class="page-content">
          <div class="container-fluid"> 
            <router-view />
          </div>
          <footer1 :profile_data="profile_data"/>
        </div>
      </div>
    </div>

    <div class="position-fixed" style="right: 3rem; bottom: 3rem;">
      <a href="https://wa.link/wet8zp" target="__blank" class="btn btn-primary rounded-circle d-flex align-items-center justify-content-center" style="aspect-ratio: 1; width: 4rem;"><img src="@/assets/whitelogo-whatsapp-png-46043.png" style="width: 2rem;" /></a>
    </div>
    
    <please-wait-modal />
  </div>

  <!-- <div v-else class="fullscreen"> -->
</template>

<script>
import $ from 'jquery'
import momentTZ from 'moment-timezone'
import Base from '@/utils/base'

import footer1 from './layout/footer'
import navbar from './layout/navbar'
import topbar from './layout/topbar'
import SideBar from './layout/sidebar'
import PleaseWaitModal from '@/layout/please_wait_modal.vue'
import NoImage from '@/assets/no_image.png';


export default {
  components: {
    footer1, navbar, topbar,
    'SideBar': SideBar,
    'please-wait-modal': PleaseWaitModal,
  },
  data() {
    return {
      base: null,
      scrollY: 0,
      flag: {
        navbar: false,
      },
      profile_data: {},
      isNavbarToggle: false,
      arr_factor: [],
      arr_menu: [
        {
          id: 'main',
          name: 'Main',
          type: 'title',
        },
        {
          id: 'dashboard',
          name: 'Dashboard',
          icon: 'mdi mdi-home',
          href: '/',
          type: 'href',
        },
      ],
    }
  },
  watch: {
    scrollY(val) {
      // console.log(this.base.responsive_scroll_threshold(500))
      this.flag.navbar = val >= this.base.responsive_scroll_threshold(200)
    },
  },
  async created() {
    this.base = new Base()
		
		// this.check_version()
    window.localStorage.setItem('arr_menu', JSON.stringify(this.arr_menu))
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1

    var flag = false
    var competition_id = this.$route.query.competition_id
    if(competition_id != null){
      window.localStorage.setItem('competition_id', competition_id)
      flag = true
    }

    var token = await window.localStorage.getItem('token')
    var token_expired = await window.localStorage.getItem('token_expired')
    if(token_expired != null)
      token_expired = momentTZ.tz(token_expired, 'Asia/Jakarta')
    // if(token == null || token == ''){
      if(this.$route.query.token != null){
        token_expired = momentTZ().add(6, 'hours')
        window.localStorage.setItem('token', this.$route.query.token)
        window.localStorage.setItem('token_expired', token_expired.format('YYYY-MM-DD HH:mm:ss'))
        token = this.$route.query.token
        flag = true
      }
      if(token_expired != null && token_expired.isBefore(momentTZ())){
        this.base.show_error("Token Expired! Please login or register at web")
        window.location.href = this.base.landing_host + "?type=logout"
        return
      }
      if(token == null || token == ''){
        this.base.show_error("Token not Found! Please login or register at web first")
        window.location.href = this.base.landing_host + "?type=logout"
        return
      }
    // }
    if(flag)
      window.location.href = "/"

    const last_version = localStorage.getItem('version')
    if (last_version == null || (last_version != null && last_version !== this.base.app_version)) {
      localStorage.setItem('version', this.base.app_version)
      window.location.reload(true);
    }
  },
  mounted(){
    $('#please_wait_modal').modal('show')
    var arr = [
      window.location.protocol + '//' + window.location.host + '/assets/js/jquery.min.js',
      'https://cdn.datatables.net/1.12.1/js/jquery.dataTables.min.js',
      // window.location.protocol + '//' + window.location.host + '/assets/js/bootstrap.bundle.min.js',
      window.location.protocol + '//' + window.location.host + '/assets/js/metisMenu.min.js',
      window.location.protocol + '//' + window.location.host + '/assets/js/waves.min.js',
      window.location.protocol + '//' + window.location.host + '/assets/js/jquery.slimscroll.min.js',
      window.location.protocol + '//' + window.location.host + '/assets/plugins/jvectormap/jquery-jvectormap-2.0.2.min.js',
      window.location.protocol + '//' + window.location.host + '/assets/plugins/jvectormap/jquery-jvectormap-world-mill-en.js',
      window.location.protocol + '//' + window.location.host + '/assets/plugins/moment/moment.js',
      window.location.protocol + '//' + window.location.host + '/assets/plugins/apexcharts/apexcharts.min.js',
      'https://apexcharts.com/samples/assets/irregular-data-series.js',
      'https://apexcharts.com/samples/assets/series1000.js',
      'https://apexcharts.com/samples/assets/ohlc.js',
      window.location.protocol + '//' + window.location.host + '/assets/pages/jquery.dashboard.init.js',
      window.location.protocol + '//' + window.location.host + '/assets/js/app.js',
      
      'https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js',
      'https://cdn.datatables.net/buttons/2.2.3/js/dataTables.buttons.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/pdfmake.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/vfs_fonts.js',
      'https://cdn.datatables.net/buttons/2.2.3/js/buttons.html5.min.js',
      'https://cdn.datatables.net/buttons/2.2.3/js/buttons.print.min.js',
      
    ]


    this.addScript(arr)
    this.get_auth()
  },
  methods: {
		async check_version(){
			var version = await window.localStorage.getItem('version')
			if(version != null){
				window.localStorage.setItem('version', this.base.version)
				window.location.reload()
			}
			else if(version != this.base.version){
				window.localStorage.setItem('version', this.base.version)
				window.location.reload()
			}
		},
    async get_auth(){
      var response = await this.base.request(this.base.url_api + "/auth/profile")

      if(response != null){
        if(response.status === "success"){
          response.data.url_image = response.data.file_name != null ? this.base.host + '/media/user?file_name=' + response.data.file_name : NoImage
          this.profile_data = response.data
        }
        // else
        //   this.base.show_error(response.message)
      }
      // else
      //   this.base.show_error(this.$t('server_error'))
    },
    addScript(arr, index = 0){
      var scriptTag = document.createElement("script");
      scriptTag.src = arr[index];
      document.getElementsByTagName('head')[0].appendChild(scriptTag);

      if(arr[index + 1] != null){
        var context = this
        setTimeout(() => {
          context.addScript(arr, index + 1)
        }, 100)
      }
      else
        $('#please_wait_modal').modal('hide')
    },
    onChangeArrFactor(arr) {
      this.arr_factor = arr
    },
    onNavbarToggle() {
      this.isNavbarToggle = !this.isNavbarToggle
    },
    handleScroll() {
      this.scrollY = window.scrollY
    }
  }
}
</script>

<style lang="scss">
html,
body {
  // font-family: poppins-regular;
  overflow-x: hidden;
}
.dt-buttons{
  display: inline-block;
  margin-bottom: 1rem;
}
.img-thumbnail{
  aspect-ratio: 1/1;
}
</style>