<template>
  <div class="" v-if="team_data.id != null">
    <div class="card">
      <div class="card-body mb-0">
        <table class="table" ref="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Image</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Name</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Position</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Gender</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Birth Date</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action</th>
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
    <ModalAddCoach :coach_data="selected_coach"/>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';

import ModalAddCoach from '@/pages/team/component/modal_add_coach';

export default {
  components: {
    'ModalAddCoach': ModalAddCoach,
  },
  props: ['team_data', 'index', 'selected_tab', 'first_time', ],
  data(){
    return{
      base: null,
      datatable: null,
      selected_coach: {},
      force_reload: false,
    }
  },
  watch: {
    selected_tab(val){
      var context = this
      if(val == this.index)
        setTimeout(() => {
          context.init_datatable()
        }, this.first_time ? this.base.wait_datatable_time : 0)
    },
    force_reload(val){
      if(val){
        this.force_reload = false
        this.$emit('onForceReload')
      }
    },
    team_data(val){
      if(val.id != null)
        this.init_datatable()
    },
  },
  mounted(){
    this.base = new Base()
    
    
  },
  methods: {
    init_datatable(){
      if(this.team_data.id != null){
        var context = this
        var category = this.team_data.event_category_sport_category

        context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/event/registration/coach?registration_event_id=' + context.$route.query.id, [[0, "asc"]], [
          {"data" : "id",},
          {"data" : "image_format", name: "registration_event_coach.file_name", "orderable" : false},
          {"data" : "name", name: "registration_event_coach.name"},
          {"data" : "coach_position_name", name: "coach_position.name"},
          {"data" : "gender_format", name: "registration_event_coach.gender"},
          {"data" : "birth_date_format", name: "registration_event_coach.birth_date"},
          {"data" : "action", "orderable" : false},
        ], [
          {
            targets: -1,
            data: null,
            sorting: false,
            render: function(data, type, row, meta) {
              var str = ""
              str += '<div style="">'
                str += `<a class="btn btn-primary" href="/team/coach/action?id=${row.id}">Edit</a>`
                str += `<button class="btn btn-danger ml-3 remove-coach" data-id="${row.id}">Delete</button>`
              str += '</div>'
              return str
            },
          },
        ], [
          {
            className: 'btn btn-primary',   
            text : "Add",
            action: function ( ) {
              window.location.href = "/team/coach/action?registration_event_id=" + context.$route.query.id
            },
            init: function(api, node, config) {
              $(node).removeClass('dt-button')
            },
          }
        ], () => {
          setTimeout(() => {
            $('.remove-coach').click(function() {
              context.force_reload = context.base.remove_action(context.base.host + '/api/event/registration/coach/delete?id=' + $(this).attr('data-id'))
            })
          }, 100)
        })
      }
    },
  },
  // watch: {
  //   selected_tab(val){
  //     var context = this
  //     if(val == this.index)
  //       setTimeout(() => {
  //         context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/event/registration/coach?registration_event_id=' + context.$route.query.id, [[0, "asc"]], [
  //           // {"data" : "id",},
  //           // {"data" : "image_format", name: "registration_event_coach.file_name", "orderable" : false},
  //           {"data" : "name", name: "registration_event_coach.name"},
  //           {"data" : "gender_format", name: "registration_event_coach.gender"},
  //           {"data" : "birth_date_format", name: "registration_event_coach.birth_date"},
  //           {"data" : "action", "orderable" : false},
  //         ], [
  //           {
  //             targets: -1,
  //             data: null,
  //             sorting: false,
  //             render: function(data, type, row, meta) {
  //               console.log(data)
  //               console.log(type)
  //               console.log(row)
  //               console.log(meta)
  //               var str = ""
  //               str += '<div style="">'
  //               str += `<a class="btn btn-primary" href="/team/coach/action?id=${row.id}">Edit</a>`
  //               str += '</div>'
  //               return str
  //             },
  //           },
  //         ], [
  //           {
  //             className: 'btn btn-primary',   
  //             text : "Add",
  //             action: function ( ) {
  //               window.location.href = "/team/coach/action?registration_event_id=" + context.$route.query.id
  //             },
  //             init: function(api, node, config) {
  //               console.log(api)
  //               console.log(config)
  //               $(node).removeClass('dt-button')
  //             },
  //           },
  //         ])
  //       }, this.base.wait_datatable_time)
  //   },
  // },
  // mounted(){
  //   this.base = new Base()
    
  // },
  // methods: {
  // }
}
</script>

<style lang="scss">
</style>
