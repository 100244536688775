<template>
  <div class="" v-if="team_data.id != null">
    <div>

      <div class="row mt-4">

        <div class="col-12 col-md-8">

          <div class="row">
            <div class="col-12 col-md-6 d-flex flex-row align-items-center" style="gap: .5rem">
              <img :src="team_data.url_image" style="width: 4rem; height: 4rem; border-radius: 4rem; object-fit: cover; object-position: center;" />
              <h4>{{team_data.team_name}}</h4>
              <a name="" id="" href="#!" data-toggle="modal" data-target="#editTeamModal" role="button">
                <font-awesome-icon icon="fa-solid fa-pencil"/>
              </a>
            </div>
            <div class="col-12 col-md-6 d-md-flex flex-row align-items-center mt-3 mt-md-0 d-none" style="gap: .5rem">
              <img :src="base.host + '/media/category-sport?file_name=' + team_data.event_category_sport_category.event_category_sport.category_sport.file_name" style="width: 4rem; height: 4rem; object-fit: cover; object-position: center;" />
              <h4>{{team_data.event_category_sport_category.event_category_sport.category_sport.name}}</h4>
            </div>
          </div>
          <!-- <div class="form-group">
            <label for="exampleInputPassword1">Team Name</label>
            <input type="text" disabled :value="team_data.team_name" class="form-control" id="exampleInputPassword1">
          </div> -->
          <div class="bg-primary small text-white rounded px-3 py-1 mt-4 d-inline-block">{{ team_data.event_category_sport_category.event_category_sport.start_date_moment.format('DD MMMM YYYY') }} - {{ team_data.event_category_sport_category.event_category_sport.end_date_moment.format('DD MMMM YYYY') }}</div>

          <table class="table mt-3">
            <tbody>
              <tr>
                <td scope="row"><b>Team Category</b></td>
                <td>{{team_data.event_category_sport_category.name}}</td>
              </tr>
              <tr>
                <td scope="row"><b>Jersey</b></td>
                <td>{{team_data.jersey_team}}</td>
              </tr>
              <tr>
                <td scope="row"><b>Other Jersey</b></td>
                <td>{{team_data.other_jersey_team}}</td>
              </tr>
            </tbody>
          </table>

          <!-- <div class="" v-if="team_data.event_category_sport_category.max_player > base.max_player_team_name">
            <button class="btn btn-outline-primary" data-toggle="modal" data-target="#editTeamModal">Edit</button>
          </div> -->

          <!-- <div class="form-group">
            <label for="exampleInputPassword1">Team Category</label>
            <input type="text" disabled :value="team_data.event_category_sport_category.name" class="form-control" id="exampleInputPassword1">
          </div>

          <div class="form-group">
            <label for="exampleInputPassword1">Jersey Team</label>
            <input type="text" disabled :value="team_data.jersey_team" class="form-control" id="exampleInputPassword1">
          </div>

          <div class="form-group">
            <label for="exampleInputPassword1">Other Jersey Team</label>
            <input type="text" disabled :value="team_data.other_jersey_team" class="form-control" id="exampleInputPassword1">
          </div> -->
        </div>
        <div class="col-12 col-md-4 mt-4 mt-md-0">
          <!-- <img :src="base.host + '/media/category-sport?file_name=' + team_data.event_category_sport_category.event_category_sport.category_sport.file_name" style="width: 10rem" />
          <div class="ml-3">
            <h4>{{ team_data.event_category_sport_category.event_category_sport.category_sport.name }}</h4>
            <div class="bg-primary text-white rounded px-3 py-1">{{ team_data.event_category_sport_category.event_category_sport.start_date_moment.format('DD MMMM YYYY') }} - {{ team_data.event_category_sport_category.event_category_sport.end_date_moment.format('DD MMMM YYYY') }}</div>
          </div> -->

          <!-- <div class="mt-3" v-if="team_data.event_category_sport_category.max_player > base.max_player_team_name">
            <p class="m-0">Image</p>
            <img :src="team_data.url_image" style="width: 10rem" />
          </div> -->
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-column">
                <label>Payment Status</label>
                <div class="rounded w-auto px-3 py-3 text-white" :class="team_data.urgent_order != null && team_data.urgent_order.status == 'success' ? 'bg-success' : team_data.urgent_order.status == 'wait_payment' ? 'bg-warning' : 'bg-danger'">{{ team_data.urgent_order != null && team_data.urgent_order.status == 'success' ? 'Success' : team_data.urgent_order.status == 'wait_payment' ? 'Wait Payment' : team_data.urgent_order.status == 'canceled' ? 'Canceled' : 'Refunded' }}</div>

              </div>

              <div class="form-group mt-3">
                <label for="exampleInputPassword1">Amount to be Paid</label>
                <input type="text" disabled :value="(team_data.urgent_order != null ? team_data.urgent_order.total_price : 0).toLocaleString(base.locale_string)" class="form-control" id="exampleInputPassword1">
              </div>
            </div>
            <div class="col-12">
              <div class="" v-if="team_data.urgent_order != null && team_data.urgent_order.detail.length > 0">
                <p class="m-0 font-weight-bold">Registered Team</p>
                <div class="d-flex justify-content-between">
                  <p class="m-0">{{ team_data.urgent_order.detail[0].amount + ' ' + team_data.urgent_order.detail[0].detail }}</p>
                  <p class="m-0">Rp. {{ (team_data.urgent_order.detail[0].amount * team_data.urgent_order.detail[0].price).toLocaleString(base.locale_string) }}</p>
                </div>
              </div>

              <div class="mt-4" v-if="team_data.urgent_order != null && team_data.urgent_order.detail.length > 1">
                <p class="m-0 font-weight-bold">Registered Match</p>
                <div class="d-flex justify-content-between" v-for="(match, index) in arr_match" :key="index">
                  <p class="m-0">{{ match.amount + ' ' + match.detail }}</p>
                  <p class="m-0">Rp. {{ (match.amount * match.price).toLocaleString(base.locale_string) }}</p>
                </div>
              </div>

              <div class="mt-4">
                <div class="d-flex justify-content-between">
                  <p class="m-0">{{ $t('subtotal') }}</p>
                  <p class="m-0">Rp. {{ (team_data.urgent_order != null ? team_data.urgent_order.subtotal : 0).toLocaleString(base.locale_string) }}</p>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="m-0">{{ $t('service_charge') }}</p>
                  <p class="m-0">Rp. {{ (team_data.urgent_order != null ? team_data.urgent_order.admin_fee : 0).toLocaleString(base.locale_string) }}</p>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="m-0 font-weight-bold">{{ $t('total') }}</p>
                  <p class="m-0 text-primary">Rp. {{ (team_data.urgent_order != null ? team_data.urgent_order.total_price : 0).toLocaleString(base.locale_string) }}</p>
                </div>
              </div>
              <!-- <div class="card">
                <div class="card-body">
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-12 my-3">
          <hr/>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import NoImage from '@/assets/no_image.png';

import CustomImagePicker from '@/layout/custom_imagepicker';


export default {
  components: {

  },
  props: ['team_data', 'index', 'selected_tab', ],
  data(){
    return{
      base: null,
      arr_category: [],
      team_name: '',
      team_category_id: '',
    }
  },
  computed: {
    arr_match(){
      var arr = []
      if(this.team_data.urgent_order != null && this.team_data.urgent_order.detail != null){
        for(let x = 1; x < this.team_data.urgent_order.detail.length; x++)
          arr.push(this.team_data.urgent_order.detail[x])
      }
      return arr
    },
  },
  created(){
    this.base = new Base()
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
