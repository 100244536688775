<template>
  <div class="">
    <CompetitionHeader :competition="registration_data.competition" v-if="registration_data.competition != null"/>
    <div>
      <p class="m-0 mb-3">Pick Matches that you would like to pay in advance</p>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12 col-sm-6 mb-3" v-for="(match, index) in arr_match" :key="index">
              <div class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="card m-0" @click="onMatchClicked(index)">
                    <div class="card-body" :class="match.is_active ? 'bg-primary text-white' : ''">
                      <p class="m-0">{{ index + 1 }}</p>
                    </div>
                  </div>
                  <p class="m-0 ml-3">Match #{{ index + 1 }}</p>
                </div>
                <div class="mt-3">
                  <p class="m-0">Schedule to be announced</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <DetailPayment
            :registration_data="registration_data"
            :arr_player="registration_data.arr_player"
            :arr_match="arr_match"
            :competition="registration_data.competition"
            :category="registration_data.team_category"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import CompetitionHeader from '@/pages/registration/component/competition_header';
import DetailPayment from '@/pages/registration/component/detail_payment';

export default {
  components: {
    'CompetitionHeader': CompetitionHeader,
    'DetailPayment': DetailPayment,
  },
  props: ['registration_data', 'before_index', 'index', 'is_submit', 'force_active', ],
  data(){
    return{
      base: null,
      arr_match: [],
    }
  },
  watch: {
    before_index(val){
      if(val == this.index)
        this.validation()
    },
    // arr_match(val){
    //   console.log(val)
    // },
    async registration_data(val){
      if(val != null){
        this.fill_data()
      }
    },
  },
  async mounted(){
    this.base = new Base()
		this.fill_data()
  },
  methods: {
		fill_data(){
			var registration_data = this.registration_data
			
			var arr_match = []
			for(let x = 0; x < 7; x++){
				var allow_clicked = true
				var is_active = this.force_active != null ? this.force_active : true
				var match_data = {}
			
				if(registration_data.arr_match != null){
					var counter = 0
					for(let match of registration_data.arr_match){
						if(match.name == x + 1 && match.id != null && match.order_detail.order.status != 'canceled'){
							match_data = match
							is_active = true
							break
						}
						counter++
					}
			
					// if(counter < registration_data.arr_match.length && match_data != null && match_data.order_detail.order.status != 'wait_payment')
					if(counter < registration_data.arr_match.length)
						allow_clicked = false
				}
			
				arr_match.push({
					id: match_data.id != null ? match_data.id : null,
					name: x + 1,
					is_active: is_active,
					allow_clicked: allow_clicked,
					status_order: match_data.order_detail != null ? match_data.order_detail.order.status_format : null,
				})
			}
			this.arr_match = arr_match
		},
    onMatchClicked(index){
      if(this.arr_match[index].allow_clicked){
        var match = this.arr_match[index]
        match.is_active = !match.is_active
        this.$set(this.arr_match, index, match)
      }
    },
    validation(){
      var arr_selected = []
      for(let match of this.arr_match){
        if(match.is_active)
          arr_selected.push(match)
      }

      this.$emit('onValidation', '', {
        arr_match: arr_selected,
      })
    },
  }
}
</script>

<style lang="scss">
</style>
