<template>
  <div class="modal fade" id="registrationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Please insert more information</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClosedClick">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex mb-3">
            <img :src="profile_data.url_image" class="rounded-circle" style="width: 3rem; height: 3rem;" />
            <div class="ml-3">
              <p class="m-0">{{ profile_data.name }}</p>
              <p class="m-0">{{ profile_data.email }}</p>
            </div>
          </div>

          <table class="w-100">
            <tr>
              <td><p class="m-0 mb-3">{{ $t('phone') }}</p></td>
              <td>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">+62</span>
                  </div>
                  <input type="text" v-model="phone_number" class="form-control">
                </div>
              </td>
            </tr>

						<tr>
              <td><p class="m-0 mb-3">{{ $t('address') }}</p></td>
              <td><textarea v-model="address" class="form-control mb-3"></textarea></td>
            </tr>

						<tr>
							<td><p class="m-0 mb-3">{{ $t('country') }}</p></td>
							<td>
								<select v-model="country" class="form-control mb-3">
									<option value="">{{ $t('select_country') }}</option>
									<option v-for="(country, index) in arr_country" :key="index" :value="country.id">{{ country.name }}</option>
									<option value="other">{{ $t('other') }}</option>
								</select>
							</td>
						</tr>

						<tr v-if="country != '' && country == 'other'">
							<td><p class="m-0 mb-3">{{ $t('name_country') }}</p></td>
							<td>
								<input type="text" v-model="name_other_country" class="form-control">
							</td>
						</tr>

            <tr v-if="arr_province.length > 0">
              <td><p class="m-0 mb-3">{{ $t('province') }}</p></td>
              <td>
                <select v-model="province" class="form-control mb-3">
                  <option value="">{{ $t('select_province') }}</option>
                  <option v-for="(province, index) in arr_province" :key="index" :value="province.id">{{ province.name }}</option>
                </select>
              </td>
            </tr>

            <tr v-if="arr_city.length > 0">
              <td><p class="m-0 mb-3">{{ $t('city') }}</p></td>
              <td>
                <select v-model="city" class="form-control mb-3">
                  <option value="">{{ $t('select_city') }}</option>
                  <option v-for="(city, index) in arr_city" :key="index" :value="city.id">{{ city.name }}</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary w-100" :disabled="!is_button_enabled" @click="onSubmit">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import NoImage from '@/assets/no_image.png';
// import moment from 'moment';

export default {
  components: {
  },
  props: ['competition', ],
  data() {
    return {
      base: null,
      profile_data: {},
      phone_number: '',
			arr_country: [],
      arr_province: [],
      arr_city: [],
      country: '',
			province: '',
      city: '',
      address: '',
			name_other_country: '',
			is_button_enabled: true,
    }
  },
  watch: {
    phone_number(val){
      this.phone_number = this.base.phone_validation(val)
    },
		country(val){
			if(val != '' && val != 'other')
				this.get_province()
		},
    province(val){
      if(val != '')
        this.get_city()
    },
  },
  created() {
    this.base = new Base()

    this.get_auth()
		this.get_country()
  },
  methods: {
    onClosedClick(){
      window.localStorage.removeItem('competition_id')
    },
    async get_country(){
			var response = await this.base.request(this.base.url_api + "/country/all")

			if(response != null){
				if(response.status === "success"){
					this.arr_country = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
		async get_province(){
			$('#please_wait_modal').modal('show')
			this.is_button_enabled = false
      var response = await this.base.request(this.base.url_api + "/province/all?country_id=" + this.country)

			setTimeout(() => {
				$('#please_wait_modal').modal('hide')
				this.is_button_enabled = true
			}, 500)
      if(response != null){
        if(response.status === "success"){
          this.arr_province = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_city(){
			$('#please_wait_modal').modal('show')
			this.is_button_enabled = false
      var response = await this.base.request(this.base.url_api + "/city/all?province_id=" + this.province)

			setTimeout(() => {
				$('#please_wait_modal').modal('hide')
				this.is_button_enabled = true
			}, 500)
      if(response != null){
        if(response.status === "success"){
          this.arr_city = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_auth(){
      var response = await this.base.request(this.base.url_api + "/auth/profile")

      if(response != null){
        if(response.status === "success"){
					response.data.url_image = response.data.file_name != null ? this.base.host + '/media/user?file_name=' + response.data.file_name : NoImage

          this.profile_data = response.data
          this.phone_number = response.data.phone.substring(3)
          this.address = response.data.address
					this.country = response.data.country.id
          if(response.data.city != null){
            this.province = response.data.city.province.id
            this.city = response.data.city.id
          }
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async onSubmit(){
      if(this.phone_number == "")
        this.base.show_error(this.$t('phone_number_empty'))
      else if(this.address == "")
        this.base.show_error(this.$t('address_empty'))
      else if(this.country == "")
        this.base.show_error(this.$t('country_empty'))
			else if(this.country == "other" && this.name_other_country == "")
				this.base.show_error(this.$t('name_country_empty'))
			else if(this.arr_province.length > 0 && this.province == "")
				this.base.show_error(this.$t('province_empty'))
      else if(this.arr_city.length > 0 && this.city == "")
        this.base.show_error(this.$t('city_empty'))
      else{
        var province = {}
        var city = {}
        var data = {}

        for(let province1 of this.arr_province){
          if(province1.id == this.province){
            province = province1
            break
          }
        }
        for(let city1 of this.arr_city){
          if(city1.id == this.city){
            city = city1
            break
          }
        }

        data = {
          phone_number: "+62" + this.phone_number,
					country: {
						id: this.country,
					},
					name_other_country: this.name_other_country,
          address: this.address,
          province: province,
          city: city,
          competition: this.competition,
        }

        $('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/auth/change-profile", 'post', {
          phone: "+62" + this.phone_number,
          address: this.address,
          city: city,
					country: {
						id: this.country,
					},
					name_other_country: this.name_other_country,
        })
        $('#please_wait_modal').modal('hide')

        if(response != null){
          if(response.status === "success"){
            window.localStorage.removeItem('competition_id')
            window.localStorage.setItem('registration_data', JSON.stringify(data))
            window.location.href = "/registration"
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))


      }
    },
  }
}
</script>

<style lang="scss">
</style>
