<template>
  <div class="topbar">
    <nav class="navbar-custom">

        <!-- LOGO -->
        <div class="topbar-left">
            <a href="/" class="logo">
              <!-- <h5>Student Open</h5> -->
                <!-- <span>
                    <img src="@/assets/logo_new.png" alt="logo-small" class="logo-sm">
                </span> -->
                <span>
                    <img src="@/assets/logo_new.png" alt="logo-large" style="height: 4rem">
                </span>
            </a>
        </div>

        <ul class="list-unstyled topbar-nav float-right mb-0">

            <!-- <li class="dropdown">
                <a class="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-toggle="dropdown" href="#" role="button"
                    aria-haspopup="false" aria-expanded="false">
                    <i class="mdi mdi-bell-outline nav-icon"></i>
                    <span class="badge badge-danger badge-pill noti-icon-badge">2</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right dropdown-lg">
                    <h6 class="dropdown-item-text">
                        Notifications (258)
                    </h6>
                    <div class="slimscroll notification-list">
                        <a href="javascript:void(0);" class="dropdown-item notify-item active">
                            <div class="notify-icon bg-success"><i class="mdi mdi-cart-outline"></i></div>
                            <p class="notify-details">Your order is placed<small class="text-muted">Dummy text of the printing and typesetting industry.</small></p>
                        </a>
                    </div>
                    <a href="javascript:void(0);" class="dropdown-item text-center text-primary">
                        View all <i class="fi-arrow-right"></i>
                    </a>
                </div>
            </li> -->

            <li class="dropdown">
                <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" href="#" role="button"
                    aria-haspopup="false" aria-expanded="false">
                    <img :src="profile_data.url_image" alt="profile-user" class="rounded-circle" style="aspect-ratio: 1 / 1; object-fit: cover; object-position: center"/> 
                    <span class="ml-1 nav-user-name hidden-sm"> <i class="mdi mdi-chevron-down"></i> </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="/auth/change-profile"><i class="dripicons-user text-muted mr-2"></i> Change Profile</a>
                    <a class="dropdown-item" href="/auth/change-password"><i class="dripicons-user text-muted mr-2"></i> Change Password</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" @click="onLogout"><i class="dripicons-exit text-muted mr-2"></i> Logout</a>
                </div>
            </li>
        </ul>

        <ul class="list-unstyled topbar-nav mb-0">
                
            <li>
                <button class="button-menu-mobile nav-link waves-effect waves-light">
                    <i class="mdi mdi-menu nav-icon"></i>
                </button>
            </li>

            <li class="hide-phone app-search">
                <form role="search" class="">
                    <input type="text" placeholder="Search..." class="form-control">
                    <a href=""><i class="fas fa-search"></i></a>
                </form>
            </li>
            
        </ul>

    </nav>
  </div>
</template>

<script>
import Base from '@/utils/base'

export default {
  props: ['scrollY', 'profile_data',],
  data() {
    return {
      base: null,
    }
  },
  created() {
    this.base = new Base()
  },
  methods: {
    onLogout(){
      window.localStorage.removeItem('token')
      window.location.href = this.base.landing_host + '?type=logout'
    },
  }
}
</script>

<style lang="scss">
</style>
