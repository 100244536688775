<template>
  <div>
    <div class="card" v-if="this.order_data.id != null">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4">
            <p><b class="font-14">Order Date :</b><br> {{ this.order_data.created_at_moment.format(base.date_format) }}</p>
            <p><b class="font-14">Order ID :</b><br> #{{ this.order_data.id }}</p>
            <p><b class="font-14">Registration ID :</b><br> {{ this.order_data.registration_event.id }}</p>

            <p v-show="this.order_data.va_no != null"><b class="font-14">VA Number :</b><br> {{ this.order_data.va_no }} <i class="mr-2 mdi mdi-content-copy" style="cursor: pointer;" @click="onCopyClicked('va_no')"></i></p>
            <p v-show="this.order_data.bill_key != null"><b class="font-14">Bill Key :</b><br> {{ this.order_data.bill_key }} <i class="mr-2 mdi mdi-content-copy" style="cursor: pointer;" @click="onCopyClicked('bill_key')"></i></p>
            <p v-show="this.order_data.biller_code != null"><b class="font-14">Biller Code :</b><br> {{ this.order_data.biller_code }} <i class="mr-2 mdi mdi-content-copy" style="cursor: pointer;" @click="onCopyClicked('biller_code')"></i></p>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <address class="font-13">
              <strong class="font-14">Billed To :</strong><br>
              {{ this.order_data.user.name }}<br>
              {{ this.order_data.user.address }}<br>
              <abbr title="Phone">Phone:</abbr> {{ this.order_data.user.phone }}
            </address>
            <p><b class="font-14">Team Name :</b><br> {{ this.order_data.registration_event.team_name }}</p>
            <p><b class="font-14">Category Sport :</b><br> {{ this.order_data.registration_event.event_category_sport_category.event_category_sport.category_sport.name }}</p>
            <!-- <div class="float-left">
            </div> -->
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <p><b class="font-14">Payment Methods :</b><br> {{ this.order_data.payment_method.name }}</p>
						<p><b class="font-14">Deadline Payment :</b><br> {{ this.order_data.payment_expired_at_moment.format('DD MMMM YYYY') }}</p>
            <h4>
              <b class="font-14">Status :</b>
              <br>
              <span v-html="this.order_data.status_format"></span>
            </h4>
						<h4 v-show="this.order_data.url_payment != null && this.order_data.payment_method.data == 'qris'">
							<b class="font-14">QR Code :</b>
							<br>
							<img :src="this.order_data.url_payment" style="width: 10rem;"/>
						</h4>
            <!-- <div class="text-center bg-light p-3 mb-3">
              <h5 class="bg-primary mt-0 p-2 text-white d-sm-inline-block w-100 text-nowrap">Payment Methods</h5>
              <p class="mb-0 text-muted">{{ this.order_data.payment_method.name }}</p>
              <h5 class="bg-primary mt-3 p-2 text-white d-sm-inline-block w-100 text-nowrap">{{ this.order_data.status_format }}</h5>
            </div> -->
          </div>

          <!-- <div class="col-12 col-sm">
            <div class="text-center bg-light p-3 mb-3">
              <h5 class="bg-primary mt-0 p-2 text-white d-sm-inline-block w-100 text-nowrap">Payment Methods</h5>
              <p class="mb-0 text-muted">{{ this.order_data.payment_method.name }}</p>
              <h5 class="bg-primary mt-3 p-2 text-white d-sm-inline-block w-100 text-nowrap">{{ this.order_data.status_format }}</h5>
            </div>
          </div> -->
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th>Quantity</th>
                    <th>Item</th>
                    <th>Unit Cost</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(detail, index) in this.order_data.detail" :key="index">
                    <th>{{ detail.amount.toLocaleString(base.locale_string) }}</th>
                    <td>{{ detail.detail }}</td>
                    <td>Rp. {{ detail.price.toLocaleString(base.locale_string) }}</td>
                    <td>Rp. {{ (detail.amount * detail.price).toLocaleString(base.locale_string) }}</td>
                  </tr>

                  <tr>
                    <td colspan="2" class="border-0"></td>
                    <td class="border-0 font-14"><b>Sub Total</b></td>
                    <td class="border-0 font-14"><b>Rp. {{ this.order_data.subtotal.toLocaleString(base.locale_string) }}</b></td>
                  </tr>
                  <tr>
                    <th colspan="2" class="border-0"></th>
                    <td class="border-0 font-14"><b>Service Charge</b></td>
                    <td class="border-0 font-14"><b>Rp. {{ this.order_data.admin_fee.toLocaleString(base.locale_string) }}</b></td>
                  </tr>
                  <tr class="bg-dark text-white">
                    <th colspan="2" class="border-0"></th>
                    <td class="border-0 font-14"><b>Total</b></td>
                    <td class="border-0 font-14"><b>Rp. {{ this.order_data.total_price.toLocaleString(base.locale_string) }}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="mt-3 d-flex">
          <button class="btn btn-outline-primary" @click="onCancelClicked">Back</button>
          <a :href="base.host + '/export/order?id=' + this.order_data.id" target="_blank" class="btn btn-outline-primary ml-3">Print</a>
          <button v-show="this.order_data.registration_event.status == 'canceled'" class="btn btn-primary ml-3" @click="onNewOrderClicked">Create New Order</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

export default {
  components: {
  },
  data(){
    return{
      base: null,
      order_data: {},
    }
  },
  mounted(){
    this.base = new Base()
    this.base.save_current_page('Detail Order', false, 'mdi mdi-home')

    this.get_order()
  },
  methods: {
    async onCopyClicked(type){
      try {
        await navigator.clipboard.writeText(this.order_data[type])
        alert('Copied');
      } catch($e) {
        alert('Cannot copy');
      }
    },
    onCancelClicked(){
      this.base.back_page()
    },
    async onNewOrderClicked(){
      var registration_data = {
        id: this.order_data.registration_event_id,
				order_id: this.order_data.id,
        payment_method: this.order_data.payment_method,
      }

      $('#please_wait_modal').modal('show')
      var response = await this.base.request(this.base.url_api + "/order/registration", 'post', registration_data)
      setTimeout(() => {
        $('#please_wait_modal').modal('hide')
      }, 500)

      this.is_submit = false
      if(response != null){
        if(response.status === "success"){
          window.location.href = "/order/detail?id=" + response.data.id
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_order(){
      var response = await this.base.request(this.base.url_api + "/order?id=" + this.$route.query.id)

      if(response != null){
        if(response.status === "success"){
          response.data.created_at_moment = momentTZ.tz(response.data.created_at, this.base.locale_timezone)
					response.data.payment_expired_at_moment = momentTZ.tz(response.data.payment_expired_at, this.base.locale_timezone)
          this.order_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
</style>
