<template>
	<div class="" v-if="match_data.id != null">
		<div>
			<h4 class="mb-4">Info Match</h4>

      <div class="card mb-4">
        <div class="card-body">
          <div class="match-grid">
            <div class="d-flex align-items-center">
              <div>
                <p class="m-0 font-weight-bold">
                  {{ match_data.name }}
                  <span class="badge badge-primary">
                    {{ match_data.status_format }}
                  </span>
                </p>
                <p class="m-0 text-secondary" v-if="match_data.group != null">
                  {{ match_data.group.event_category_sport_category.name }} /
                  {{ match_data.group.name }}
                </p>
                <p class="m-0 text-secondary" v-else-if="match_data.cutoff_group != null">
                  {{ match_data.cutoff_group.event_category_sport_category.name }} / Round
                  {{ match_data.cutoff_group.cutoff_category.name }}
                </p>
              </div>
            </div>
    
            <div class="d-flex flex-column align-items-center">
              <p class="m-0 font-weight-bold">{{ match_data.date_moment.format("DD MMMM YYYY") }}</p>
              <p class="m-0 text-secondary">{{ match_data.date_moment.format("HH:mm") }}</p>
            </div>
    
            <div class="text-right">
              <p class="m-0 font-weight-bold">
                {{
                  match_data.event_category_sport_venue != null
                    ? match_data.event_category_sport_venue.venue.name
                    : "No Venue"
                }}
              </p>
              <p class="m-0 text-secondary">
                {{
                  match_data.event_category_sport_venue != null
                    ? match_data.event_category_sport_venue.court
                    : "No Court"
                }}
              </p>
            </div>
          </div>
    
          <div class="d-flex align-items-center justify-content-between">
            <div
              class="d-flex align-items-center justify-content-center justify-content-md-start flex-column flex-md-row member-container">
              <div class="d-flex align-items-center justify-content-center match-image-container">
                <img :src="match_data.member1.registration_event.url_image" />
              </div>
              <div class="text-center text-md-left mt-2 mt-md-0 ml-md-3">
                <p class="m-0 font-weight-bold">{{ match_data.member1.registration_event.user.name }}</p>
                <p class="m-0">{{ match_data.member1.registration_event.team_name }}</p>
                <p class="m-0 text-secondary">{{ match_data.member1.registration_event.jersey_team }}</p>
              </div>
            </div>
    
            <div class="d-flex align-items-center justify-content-center score-container">
              <h4 class="font-weight-bold">{{ match_data.group_member1_score }}</h4>
              <h4 class="mx-3">-</h4>
              <h4 class="font-weight-bold">{{ match_data.group_member2_score }}</h4>
            </div>
    
            <div
              class="d-flex align-items-center justify-content-end flex-column-reverse flex-md-row member-container">
              <div class="text-center text-md-right mt-2 mt-md-0 mr-md-3">
                <p class="m-0 font-weight-bold">{{ match_data.member2.registration_event.user.name }}</p>
                <p class="m-0">{{ match_data.member2.registration_event.team_name }}</p>
                <p class="m-0 text-secondary">{{ match_data.member2.registration_event.jersey_team }}</p>
              </div>
              <div class="d-flex align-items-center justify-content-center match-image-container">
                <img :src="match_data.member2.registration_event.url_image" />
              </div>
            </div>
          </div>

        </div>
      </div>


			<!-- <div class="row">
				<div class="col-6">
					<div class="form-group">
						<label for="exampleInputPassword1">Home Team Name</label>
						<input
							type="text"
							disabled
							:value="match_data.member1.registration_event.team_name"
							class="form-control"
							id="exampleInputPassword1" />
					</div>
				</div>

				<div class="col-6">
					<div class="form-group">
						<label for="exampleInputPassword1">Away Team Name</label>
						<input
							type="text"
							disabled
							:value="match_data.member2.registration_event.team_name"
							class="form-control"
							id="exampleInputPassword1" />
					</div>
				</div>

				<div class="col-6">
					<div class="form-group">
						<label for="exampleInputPassword1">Home Score</label>
						<input
							type="text"
							disabled
							:value="match_data.group_member1_score"
							class="form-control"
							id="exampleInputPassword1" />
					</div>
				</div>

				<div class="col-6">
					<div class="form-group">
						<label for="exampleInputPassword1">Away Score</label>
						<input
							type="text"
							disabled
							:value="match_data.group_member2_score"
							class="form-control"
							id="exampleInputPassword1" />
					</div>
				</div>

				<div class="col-6">
					<div class="form-group">
						<label for="exampleInputPassword1">Venue</label>
						<input
							type="text"
							disabled
							:value="
								match_data.event_category_sport_venue != null
									? match_data.event_category_sport_venue.venue.name
									: 'No Venue'
							"
							class="form-control"
							id="exampleInputPassword1" />
					</div>
				</div>

				<div class="col-6">
					<div class="form-group">
						<label for="exampleInputPassword1">Court</label>
						<input
							type="text"
							disabled
							:value="
								match_data.event_category_sport_venue != null
									? match_data.event_category_sport_venue.venue.court
									: 'No Court'
							"
							class="form-control"
							id="exampleInputPassword1" />
					</div>
				</div>

				<div class="col-6">
					<div class="form-group">
						<label for="exampleInputPassword1">Date</label>
						<input
							type="text"
							disabled
							:value="match_data.date_moment.format('DD MMMM YYYY HH:mm')"
							class="form-control"
							id="exampleInputPassword1" />
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import Base from "@/utils/base";
	import NoImage from "@/assets/no_image.png";

	import CustomImagePicker from "@/layout/custom_imagepicker";

	export default {
		components: {},
		props: ["match_data", "index", "selected_tab"],
		data() {
			return {
				base: null,
				arr_category: [],
				team_name: "",
				team_category_id: "",
			};
		},
		created() {
			this.base = new Base();
		},
		methods: {},
	};
</script>

<style lang="scss">
	@import "../../../assets/scss/app.scss";

@include media-breakpoint-up(md) {
  .text-md-normal {
    // font-size: 1rem;
  }
  .score-container{
    height: 5rem;
  }

  .member-container {
    p {
      font-size: 1rem;
    }

    .match-image-container {
      height: 5rem;

      img {
        height: 5rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .text-small {
    // font-size: 0.875rem;
  }
  .score-container{
    height: 4rem;
  }

  .member-container {
    p {
      // font-size: 0.875rem;
    }

    .match-image-container {
      height: 4rem;

      img {
        height: 4rem;
      }
    }
  }
}

.member-container {
	width: 20rem;
}

.match-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 1fr;
  gap: 1rem;
}

.competition-detail-venue-court {
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}
</style>
