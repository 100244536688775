<template>
  <footer class="footer text-center text-sm-left">
      &copy; 2024 Student Open <span class="text-muted d-none d-sm-inline-block float-right">Crafted with <i class="mdi mdi-heart text-danger"></i> by QuantumTri</span>
  </footer>
</template>

<script>
import Base from '@/utils/base';

export default {
  components: {
  },
  data(){
    return{
      base: null,
    }
  },
  created(){
    this.base = new Base()
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>