<template>
  <div class="card mt-4">
    <div class="card-body">
      <div class="d-flex flex-column flex-md-row align-items-center">
        <img :src="competition.category_sport.image" style="width: 10rem" />
        <div class="ml-3">
          <h4 class="mt-3 mt-md-0 text-center text-md-left">{{ competition.category_sport.name }}</h4>
          <div class="bg-primary text-white text-center rounded px-3 py-1">{{ competition.start_date_moment.format('DD MMMM YYYY') }} - {{ competition.end_date_moment.format('DD MMMM YYYY') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

export default {
  components: {
  },
  props: ['competition', ],
  data(){
    return{
      base: null,
    }
  },
  created(){
    this.base = new Base()
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
