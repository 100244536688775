<template>
  <div class="" v-if="team_data.id != null">
    <div class="card">
      <div class="card-body mb-0">
        <table class="table" ref="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <!-- <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Image</th> -->
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Detail</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Date</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Status</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action</th>
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';

export default {
  components: {
  },
  props: ['team_data', 'index', 'selected_tab', 'first_time', ],
  data(){
    return{
      base: null,
      datatable: null,
      selected_coach: {},
      force_reload: false,
    }
  },
  watch: {
    selected_tab(val){
      var context = this
      if(val == this.index)
        setTimeout(() => {
          context.init_datatable()
        }, this.first_time ? this.base.wait_datatable_time : 0)
    },
    force_reload(val){
      if(val){
        this.force_reload = false
        this.$emit('onForceReload')
      }
    },
    team_data(val){
      if(val.id != null)
        this.init_datatable()
    },
  },
  mounted(){
    this.base = new Base()
    var context = this
  },
  methods: {
    init_datatable(){
      var context = this
      var category = this.team_data.event_category_sport_category

      context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/order?registration_event_id=' + context.$route.query.id, [[0, "desc"]], [
        {"data" : "id",},
        // {"data" : "image_format", name: "registration_event_match.file_name", "orderable" : false},
        {"data" : "detail_text", name: "order_detail.name_text"},
        {"data" : "created_at_format", name: "order.created_at"},
        {"data" : "status_format", name: "order.status"},
        {"data" : "action", "orderable" : false},
      ], [
        {
          targets: -1,
          data: null,
          sorting: false,
          render: function(data, type, row, meta) {
            var str = ""
            str += '<div style="">'
              str += `<a class="btn btn-primary" href="/order/detail?id=${row.id}">Detail</a>`
              if(row.status == 'wait_payment' && row.url_payment != null)
                str += `<a target="_blank" href="${row.url_payment}" class="btn btn-primary ml-3">Pay</a>`
              if(row.status == 'canceled' && row.reorder_order_id == null)
                str += `<button class="btn btn-primary ml-3 reorder" row='${JSON.stringify(row)}'>Reorder</button>`
            str += '</div>'
            return str
          },
        },
      ], [], function() {
        setTimeout(() => {
          $('.reorder').click(function() {
            var row = JSON.parse($(this).attr('row'))

            window.location.href = "/registration/reorder?id=" + row.registration_event_id + '&order_id=' + row.id
          })
        }, 100)
      })
    },
  },
}
</script>

<style lang="scss">
</style>
