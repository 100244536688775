<template>
  <div class="">
    <div class="card">
      <div class="card-body mb-0">
        <table class="table" ref="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Category Sport</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Category</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Total Price</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Created Date</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Paid Date</th>
              <!-- <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action</th> -->
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

export default {
  components: {
  },
  data(){
    return{
      base: null,
      arr_competition: [],
      selected_competition: {},
      scrollY: 0,
      datatable: null,
    }
  },
  mounted(){
    this.base = new Base()
    var context = this

    this.base.save_current_page('Payment History', true, 'mdi mdi-home')
    setTimeout(() => {
      context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/order?status=success', [[0, "desc"]], [
        {"data" : "id",},
        {"data" : "category_sport_name", name: "category_sport.name"},
        {"data" : "event_category_sport_category_name", name: "event_category_sport_category.name"},
        {"data" : "total_price_format", name: "order.total_price"},
        {"data" : "created_at_format", name: "order.created_at"},
        {"data" : "paid_at_format", name: "order.paid_at"},
        // {"data" : "action", "orderable" : false},
      ])
    }, this.base.wait_datatable_time)
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
</style>
