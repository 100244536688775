<template>
  <div class="" v-if="match_data.id != null">
    <div class="card">
      <div class="card-body mb-0">
        <table class="table" ref="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Minute</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Type</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Player Name</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Score</th>
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';

export default {
  components: {
  },
  props: ['match_data', 'index', 'selected_tab', 'first_time', ],
  data(){
    return{
      base: null,
      datatable: null,
      selected_coach: {},
      force_reload: false,
    }
  },
  watch: {
    selected_tab(val){
      var context = this
      if(val == this.index)
        setTimeout(() => {
          context.init_datatable()
        }, this.first_time ? this.base.wait_datatable_time : 0)
    },
    force_reload(val){
      if(val){
        this.force_reload = false
        this.$emit('onForceReload')
      }
    },
    match_data(val){
      if(val.id != null)
        this.init_datatable()
    },
  },
  mounted(){
    this.base = new Base()
    var context = this
  },
  methods: {
    init_datatable(){
      var context = this

      context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/match/event?match_id=' + context.$route.query.id, [[1, "asc"]], [
        {"data" : "id",},
        {"data" : "minute_time", name: "match_event.minute_time"},
        {"data" : "type_format", name: "match_event.type"},
        {"data" : "registration_event_player_name", name: "registration_event_player.name"},
        {"data" : "total_score", name: "match_event.total_score"},
      ])
    },
  },
}
</script>

<style lang="scss">
</style>
