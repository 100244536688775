<template>
  <div class="">
    <div class="card">
      <div class="card-body mb-0">
        <table class="table" ref="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Team Name</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action</th>
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

import Base from '@/utils/base';

export default {
  components: {
  },
  data(){
    return{
      base: null,
      arr_competition: [],
      selected_competition: {},
      scrollY: 0,
      datatable: null,
    }
  },
  mounted(){
    this.base = new Base()
    var context = this

    this.base.save_current_page('List Team', true, 'mdi mdi-home')
    setTimeout(() => {
      context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/event/registration?api_type=has_match&rel_type=simple', [[0, "asc"]], [
        {"data" : "id",},
        {"data" : "team_name", name: "registration_event.team_name"},
        {"data" : "action", "orderable" : false},
      ], [
        {
          targets: -1,
          data: null,
          sorting: false,
          render: function(data, type, row, meta) {
            var str = ""
            str += '<div style="width: 150px">'
              str += `<a class="btn btn-primary" href="/match/list?registration_event_id=${row.id}">List Match</a>`
            str += '</div>'
            return str
          },
        },
      ])
      
      
      
    }, this.base.wait_datatable_time)

    
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
</style>
