<template>
  <div class="left-sidenav">      
    <ul class="metismenu left-sidenav-menu" id="side-nav">
      <li class="menu-title">Main</li>

      <li><a href="/dashboard"><i class="mdi mdi-home"></i><span>Dashboard</span></a></li>
      <li><a href="/"><i class="mdi mdi-file-document-arrow-right"></i><span>Registration</span></a></li>
      <li><a href="/order"><i class="mdi mdi-invoice-clock-outline"></i><span>Active Order</span></a></li>
      <li><a href="/payment-history"><i class="mdi mdi-invoice-check"></i><span>Payment History</span></a></li>
      <li><a href="/team"><i class="mdi mdi-account-group"></i><span>Team</span></a></li>
      <li><a href="/team/draft"><i class="mdi mdi-account-group-outline"></i><span>Team Draft</span></a></li>
      <li><a href="/match"><i class="mdi mdi-sword-cross"></i><span>Active Match</span></a></li>
    </ul>
  </div>
</template>

<script>
import Base from '@/utils/base'

export default {
  props: ['scrollY',],
  data() {
    return {
      base: null,
      
    }
  },
  created() {
    this.base = new Base()
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
