<template>
  <div class="">
    <div class="card">
      <div class="card-body mb-0">
        <div class="row">
          <div class="col-12 align-self-center">
            <div class="">
              <h4 class="mt-0 header-title">Welcome !</h4>
              <h5 class="mt-0 text-dark">What would you like to do today? Select your actions through the options below</h5>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end card-body-->
    </div>

    <div class="card">
      <div class="card-body mb-0">
        <div class="row">
          <div class="col-12 align-self-center">
            <div class="">
              <h4 class="mt-0 header-title">Sports Registration</h4>

              <!-- <div class="row">
                <div class="col-12 col-md-4 col-lg-3" v-for="(competition, index) in arr_competition" :key="index">
                  <div class="card shadow-sm" >
                    <div class="card-body position-relative">
                      <div class="position-absolute px-3 py-1 competition-status" :class="'competition-style-' + competition.status_data">{{ competition.status }}</div>
                      <div class="text-center w-100">
                        <img :src="competition.category_sport != null ? competition.category_sport.image : '@/assets/no_image.png'" class="" style="height: 10rem; max-width: 100%;">
                      </div>

                      <div class="mt-3">
                        <p class="mb-1 text-primary our-competition-name text-center">{{ competition.name }}</p>
                        <div class="text-center">
                          <button class="btn btn-primary our-competition-register text-warning" @click="onRegisterClicked(index)" style="width: 10rem; max-width: 100%;">{{ $t('register') }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="d-flex flex-wrap" style="">
                <div class="col" v-for="(competition, index) in arr_competition" :key="index">
                  <div class="card shadow" >
                    <div class="card-body position-relative">
                      <div class="position-absolute px-3 py-1 competition-status" :class="'competition-style-' + competition.status_data">{{ competition.status }}</div>
                      <div class="text-center w-100">
                        <img :src="competition.category_sport != null ? competition.category_sport.image : '@/assets/no_image.png'" class="" style="height: 10rem; max-width: 100%;">
                      </div>

                      <div class="mt-3">
                        <p class="mb-1 text-primary our-competition-name text-center">{{ competition.name }}</p>
                        <div class="text-center">
                          <button class="btn btn-primary our-competition-register text-warning" @click="onRegisterClicked(index)" :disabled="competition.status_data != 'open'" style="width: 10rem; max-width: 100%;">{{ $t('register') }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end card-body-->
    </div>

    <div class="card d-none">
      <div class="card-body mb-0">
        <div class="row">
          <div class="col-12 col-md-6 align-self-center">
            <div class="">
              <h4 class="mt-0 header-title">Sports Agenda</h4>

              <div >
                <div class="card shadow-sm" v-for="(competition, index) in arr_competition" :key="index">
                  <div class="card-body position-relative">
                    <div class="position-absolute px-3 py-1 competition-status" :class="'competition-style-' + competition.status_data">{{ competition.status }}</div>
                    <div class="text-center w-100">
                      <img :src="competition.category_sport != null ? competition.category_sport.image : '@/assets/no_image.png'" class="" style="height: 10rem; max-width: 100%;">
                    </div>

                    <div class="mt-3">
                      <p class="mb-1 text-primary our-competition-name text-center">{{ competition.name }}</p>
                      <div class="text-center">
                        <button class="btn btn-primary our-competition-register text-warning" style="width: 10rem; max-width: 100%;">{{ $t('register') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end card-body-->
    </div>

    <ModalRegistration :competition="selected_competition"/>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

import ModalRegistration from '@/pages/home/component/modal_registration';

export default {
  components: {
    'ModalRegistration': ModalRegistration,
  },
  data(){
    return{
      base: null,
      arr_competition: [],
      selected_competition: {},
      scrollY: 0,
      profile_data: {},
    }
  },
  watch: {
    async arr_competition(val){
      if(val != null && val.length > 0){
        var competition_id = await window.localStorage.getItem('competition_id')
        setTimeout(() => {
          if(competition_id != null){
            var competition_data = {}
            for(let competition of val){
              if(competition_id == competition.id){
                competition_data = competition
                break
              }
            }

            if(this.profile_data.phone == null || this.profile_data.city == null || this.profile_data.address == null){
              this.selected_competition = competition_data
              $('#registrationModal').modal('show')
            }
            else{
              var data = {
                phone_number: this.profile_data.phone_number,
                address: this.profile_data.address,
								country: this.profile_data.country,
                city: this.profile_data.city,
                competition: competition_data,
              }
							if(this.profile_data.city != null)
								data.province = this.profile_data.city.province

              window.localStorage.removeItem('competition_id')
              window.localStorage.setItem('registration_data', JSON.stringify(data))
              window.location.href = "/registration"
            }
          }
        }, 1000)

      }
    },
  },
  async created(){
    this.base = new Base()

    this.base.save_current_page('Home', true, 'mdi mdi-home')
    await this.get_auth()
    await this.get_event_category_sport()
  },
  methods: {
    onRegisterClicked(index){
      if(this.profile_data.phone == null || this.profile_data.city_id == null || this.profile_data.address == null){
        this.selected_competition = this.arr_competition[index]
        $('#registrationModal').modal('show')
      }
      else{
        var data = {
          phone_number: this.profile_data.phone_number,
          address: this.profile_data.address,
					country: this.profile_data.country,
          city: this.profile_data.city,
          competition: this.arr_competition[index],
        }
				if(this.profile_data.city != null)
					data.province = this.profile_data.city.province

        window.localStorage.removeItem('competition_id')
        window.localStorage.setItem('registration_data', JSON.stringify(data))
        window.location.href = "/registration"
      }
    },
    async get_auth(){
      var response = await this.base.request(this.base.url_api + "/auth/profile")

      if(response != null){
        if(response.status === "success"){
          this.profile_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_event_category_sport(){
      var response = await this.base.request(this.base.url_api + "/event/category-sport/all?is_publish=1")
      this.isLoading = false

      if(response != null){
        if(response.status === "success"){
          for(let event_category_sport of response.data){
            event_category_sport.category_sport.image = this.base.host + "/media/category-sport?file_name=" + event_category_sport.category_sport.file_name
            event_category_sport.event.start_school_registration_moment = momentTZ.tz(event_category_sport.event.start_school_registration, this.base.locale_timezone)
            event_category_sport.event.end_school_registration_moment = momentTZ.tz(event_category_sport.event.end_school_registration, this.base.locale_timezone)

            // var total_team = 0
            // var total_registration = 0
            // for(let x in event_category_sport.category){
            //   total_team += event_category_sport.category[x].max_total_team_per_school + event_category_sport.category[x].max_total_team_per_club
            //   total_registration += event_category_sport.category[x].register.length;
            // }
            event_category_sport.status = momentTZ().isBefore(event_category_sport.event.start_school_registration_moment) ? 'Soon' : (momentTZ().isAfter(event_category_sport.event.end_school_registration_moment) || event_category_sport.total_registered_not_canceled >= event_category_sport.total_max_registration || event_category_sport.is_match_generated ? 'Closed' : 'Open')
            event_category_sport.status_data = event_category_sport.status.toLowerCase()
            event_category_sport.name = event_category_sport.category_sport.name
          }
          this.arr_competition = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
.competition-status{
  right: 1rem;
  top: 1rem;
  border-radius: .3rem;
}
.competition-style-open{
  background-color: $green2;
  color: $green1;
}
.competition-style-closed{
  background-color: $red2;
  color: $red1;
}
.competition-style-soon{
  background-color: $yellow2;
  color: $yellow1;
}
</style>
