import Vue from 'vue'
import Router from 'vue-router'
// import router_view from '@/router_view'

import home from '@/pages/home/index'
import change_password from '@/pages/auth/change_password'
import change_profile from '@/pages/auth/change_profile'
import dashboard from '@/pages/dashboard/index'
import registration from '@/pages/registration/index'
import registration_success from '@/pages/registration/success'
import registration_reorder from '@/pages/registration/reorder'
import order from '@/pages/order/index'
import order_detail from '@/pages/order/detail'
import payment_history from '@/pages/payment_history/index'
import match from '@/pages/match/index'
import list_match from '@/pages/match/index_match'
import detail_match from '@/pages/match/detail'
import team_draft from '@/pages/team/index_draft'
import team from '@/pages/team/index'
import detail_team from '@/pages/team/detail'
import edit_coach from '@/pages/team/edit_coach'
import edit_player from '@/pages/team/edit_player'
import match_registration from '@/pages/match_registration'

Vue.use(Router)

var router = new Router({
  routes: [
    {
      path: '',
      component: home,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/dashboard',
      component: dashboard,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/registration',
      component: registration,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/registration/success',
      component: registration_success,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/registration/reorder',
      component: registration_reorder,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/auth/change-password',
      component: change_password,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/auth/change-profile',
      component: change_profile,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/order',
      component: order,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/order/detail',
      component: order_detail,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/payment-history',
      component: payment_history,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/match',
      component: match,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/match/list',
      component: list_match,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/match/detail',
      component: detail_match,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/team/draft',
      component: team_draft,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/team',
      component: team,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/team/detail',
      component: detail_team,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/team/coach/action',
      component: edit_coach,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/team/player/action',
      component: edit_player,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/team/registration/match',
      component: match_registration,
      meta: {
        requiresAuth: false
      },
    },
  ],
  mode: 'history',
})

export default router
