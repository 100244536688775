<template>
  <div class="" v-if="team_data.id != null">
    <div class="card">
      <div class="card-body mb-0">
        <table class="table" ref="table">
          <thead>
            <tr>
              <!-- <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th> -->
              <!-- <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Image</th> -->
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Match</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Status</th>
              <!-- <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action</th> -->
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';

export default {
  components: {
  },
  props: ['team_data', 'index', 'selected_tab', 'first_time', ],
  data(){
    return{
      base: null,
      datatable: null,
      selected_coach: {},
      force_reload: false,
    }
  },
  watch: {
    selected_tab(val){
      var context = this
      if(val == this.index)
        setTimeout(() => {
          context.init_datatable()
        }, this.first_time ? this.base.wait_datatable_time : 0)
    },
    force_reload(val){
      if(val){
        this.force_reload = false
        this.$emit('onForceReload')
      }
    },
    team_data(val){
      if(val.id != null)
        this.init_datatable()
    },
  },
  mounted(){
    this.base = new Base()
    var context = this

    setTimeout(() => {
      var context = this
      $('.remove-match').click(function() {
        context.force_reload = context.base.remove_action(context.base.host + '/api/event/registration/match/delete?id=' + $(this).attr('data-id'))
      })
    }, this.first_time ? this.base.wait_datatable_time + 3000 : 3000)
  },
  methods: {
    init_datatable(){
      if(this.team_data.id != null){
        var context = this
        var category = this.team_data.event_category_sport_category
        var arr_button = []

        if(this.team_data.arr_match.length < 7)
          arr_button.push({
            className: 'btn btn-primary',   
            text : "Pay other Match",
            action: function ( ) {
              window.location.href = "/team/registration/match?registration_event_id=" + context.$route.query.id
            },
            init: function(api, node, config) {
              $(node).removeClass('dt-button')
            },
          })

        context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/event/registration/match?registration_event_id=' + context.$route.query.id, [[0, "asc"]], [
          // {"data" : "id",},
          // {"data" : "image_format", name: "registration_event_match.file_name", "orderable" : false},
          {"data" : "name", name: "registration_event_match.name"},
          {"data" : "status_format", name: "order.status"},
          // {"data" : "action", "orderable" : false},
        ], [
          // {
          //   targets: -1,
          //   data: null,
          //   sorting: false,
          //   render: function(data, type, row, meta) {
          //     var str = ""
          //     str += '<div style="">'
          //       // str += `<a class="btn btn-primary" href="/team/coach/action?id=${row.id}">Edit</a>`
          //       if(row.order_detail.order.status == 'wait_payment')
          //         str += `<button class="btn btn-danger ml-3 remove-match" data-id="${row.id}">Remove</button>`
          //     str += '</div>'
          //     return str
          //   },
          // },
        ], arr_button, () => {
          setTimeout(() => {
            $('.remove-match').click(function() {
              context.force_reload = context.base.remove_action(context.base.host + '/api/event/registration/match/delete?id=' + $(this).attr('data-id'))
            })
          }, 100)
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
