<template>
  <div class="card">
    <div class="card-body">
      <table class="w-100">
        <tr>
          <td><p class="m-0 mb-3">Position</p></td>
          <td>
            <v-select @search="onPositionSearch" v-model="selected_position" :options="arr_position" class="mb-3"/>
          </td>
        </tr>
        <tr>
          <td><p class="m-0 mb-3">{{ $t('name') }}</p></td>
          <td>
            <input type="text" v-model="name" class="form-control mb-3">
          </td>
        </tr>
        <tr>
          <td><p class="m-0 mb-3">Gender</p></td>
          <td>
            <select v-model="gender" class="form-control mb-3">
              <option value="">Select Gender</option>
              <option value="1">Male</option>
              <option value="0">Female</option>
            </select>
          </td>
        </tr>
        <tr>
          <td><p class="m-0 mb-3">Birth Date</p></td>
          <td><date-picker v-model="birth_date" type="date" :default-value="momentNow.toDate()" value-type="format" input-class="form-control mb-3" :format="base.date_format" :disabled-date="disableBirthDate"/></td>
        </tr>
        <tr>
          <td><p class="m-0 mb-3">Image</p></td>
          <td>
            <CustomImagePicker
              :image_data1="image_data"
              @onResponse="onImageResponse"/>
          </td>
        </tr>
      </table>
      <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-outline-primary" @click="onCancel">Cancel</button>
        <button type="button" class="btn btn-primary" @click="onSubmit">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import DatePicker from 'vue2-datepicker';
import momentTZ from 'moment-timezone';
import 'vue2-datepicker/index.css';
// import moment from 'moment';

import CustomImagePicker from '@/layout/custom_imagepicker';
import NoImage from '@/assets/no_image.png';

export default {
  components: {
    DatePicker,
    'CustomImagePicker': CustomImagePicker,
  },
  props: [],
  data() {
    return {
      base: null,
      coach_data: {},
      arr_position: [],
      selected_position: {},
      registration_event_id: '',
      id: '',
      name: '',
      gender: 1,
      birth_date: '',
      image_data: {},
      momentNow: null,
      team_data: {},
    }
  },
  watch: {
    coach_data(val){
      this.id = val.id != null ? val.id : ''
      this.name = val.id != null ? val.name : ''
      this.gender = val.id != null ? val.gender : 1
      this.birth_date = val.id != null ? val.birth_date_moment.format(this.base.date_format) : ''
      this.image_data = val.id != null ? {image_display: val.url_image, image_data: null} : {}
      this.selected_position = val.id != null ? val.coach_position : {}
      this.registration_event_id = val.id != null ? val.registration_event_id : this.$route.query.registration_event_id
    },
  },
  async created() {
    this.base = new Base()
    this.momentNow = momentTZ().subtract(this.base.min_age, 'y')

    this.base.save_current_page((this.$route.query.id != null ? 'Edit' : 'Add') + ' Coach', false, 'mdi mdi-file-document')
    this.registration_event_id = this.$route.query.registration_event_id

    $('#please_wait_modal').modal('show')
    if(this.$route.query.id != null)
      await this.get_coach()
    else if(this.$route.query.registration_event_id != null)
      await this.get_registration()
    this.onPositionSearch('')
  },
  methods: {
    disableBirthDate(date){
      return momentTZ(date).isSameOrAfter(momentTZ().subtract(this.base.min_age, 'y'))
    },
    async get_coach(){
      var response = await this.base.request(this.base.url_api + "/event/registration/coach?id=" + this.$route.query.id)

      if(response != null){
        if(response.status === "success"){
          response.data.birth_date_moment = response.data.birth_date != null ? momentTZ.tz(response.data.birth_date, this.base.locale_timezone) : this.momentNow
          response.data.url_image = response.data.file_name != null ? this.base.host + '/media/registration/coach?file_name=' + response.data.file_name : NoImage
          if(response.data.coach_position != null)
            response.data.coach_position.label = response.data.coach_position.name
          this.coach_data = response.data
          this.team_data = response.data.registration_event
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_registration(){
      var response = await this.base.request(this.base.url_api + "/event/registration?id=" + this.$route.query.registration_event_id)

      if(response != null){
        if(response.status === "success"){
          this.team_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async onPositionSearch(search){
      
      var response = await this.base.request(this.base.url_api + "/coach-position/all?category_sport_id=" + this.team_data.event_category_sport_category.event_category_sport.category_sport.id + "&search=" + search)
      setTimeout(() => {
        $('#please_wait_modal').modal('hide')
      }, 500)

      if(response != null){
        if(response.status === "success"){
          for(let position of response.data){
            position.label = position.name
          }
          this.arr_position = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    onImageResponse(image_data){
      this.image_data = image_data
    },
    onCancel(){
      this.base.back_page()
    },
    async onSubmit(){
      if(this.selected_position == null || this.selected_position.id == null)
        this.base.show_error('Position not Selected yet')
      else if(this.name == "")
        this.base.show_error('Name is Empty')
      else if(this.birth_date == "")
        this.base.show_error('Birth Date is Empty')
      else{
        var url = this.base.url_api + "/event/registration/coach"
        var data = {}

        data = {
          coach_position: this.selected_position,
          registration_event_id: this.registration_event_id,
          name: this.name,
          gender: this.gender,
          birth_date: momentTZ(this.birth_date, this.base.date_format).format('YYYY-MM-DD'),
        }

        if(this.image_data.data_image != null)
          data.image = {
            file: this.image_data.data_image,
          }

        if(this.id != ''){
          data.id = this.id
          url += "/edit"
        }

        $('#please_wait_modal').modal('show')
        var response = await this.base.request(url, 'post', data)

        setTimeout(() => {
          $('#please_wait_modal').modal('hide')
        }, 500)
        
        if(response != null){
          if(response.status === "success"){
            this.base.back_page()
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    },
  }
}
</script>

<style lang="scss">
</style>
