<template>
  <div class="">
    <div class="card">
      <div class="card-body mb-0">
        <table class="table team-table-draft" ref="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Image</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Category Sport</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Team Name</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Category</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Total Player</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Registration Status</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Match Status</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action</th>
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

import Base from '@/utils/base';

export default {
  components: {
  },
  data(){
    return{
      base: null,
      arr_competition: [],
      selected_competition: {},
      scrollY: 0,
      datatable: null,
    }
  },
  mounted(){
    this.base = new Base()
    var context = this

    this.base.save_current_page('Team Draft', true, 'mdi mdi-home')
    setTimeout(() => {
      context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/event/registration?type=draft&rel_type=simple', [[0, "desc"]], [
        {"data" : "id",},
        {"data" : "image_format", name: "registration_event.file_name"},
        {"data" : "category_sport_name", name: "category_sport.name"},
        {"data" : "team_name", name: "registration_event.team_name"},
        {"data" : "event_category_sport_category_name", name: "event_category_sport_category.name"},
        {"data" : "total_player", name: "total_player"},
        {"data" : "status_registration_format", name: "registration_event.status"},
        {"data" : "status_match_format", name: "registration_event.status"},
        {"data" : "action", "orderable" : false},
      ], [
        {
          targets: -1,
          data: null,
          sorting: false,
          render: function(data, type, row, meta) {
            var str = ""
            str += '<div style="width: 150px">'
              str += `<button class="btn btn-primary continue-registration" row='${JSON.stringify(row)}'>Continue Registration</button>`
              if(row.status_registration != "registration_paid" || row.status_match != "match_paid")
                str += `<button class="btn btn-danger ml-3 remove-team" row='${JSON.stringify(row)}'>Delete</button>`
            str += '</div>'
            return str
          },
        },
      ], [], function() {
        setTimeout(() => {
          $('.continue-registration').click(async function() {
            var row = JSON.parse($(this).attr('row'))
            row.team_category_id = row.event_category_sport_category.id
            row.image_data = {
              image_display: row.url_image,
              image_data: null,
            }
						row.city = {
							id: row.city_id
						}

            window.localStorage.setItem('registration_data', JSON.stringify(row))
            window.location.href = "/registration"
          })
          $('.remove-team').click(async function() {
            var row = JSON.parse($(this).attr('row'))

            if(confirm('Are You Sure to Delete this data?')){
              var response = await context.base.request(context.base.url_api + "/event/registration/delete?id=" + row.id)

              if(response != null){
                if(response.status === "success"){
                  window.location.reload()
                }
                else
                  context.base.show_error(response.message)
              }
            }
          })
        }, 100)
      })
      
      
      
    }, this.base.wait_datatable_time)

    
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
table{
  tbody{
    tr{
      td{
        img{
          width: 5rem !important;
          height: 5rem;
          border-radius: 5rem;
          object-fit: cover;
          object-position: top;
        }
      }
    }
  }
}
table.team-table-draft{
  tbody{
    tr{
      td:nth-child(7){
        text-align: center;
      }
      td:nth-child(8){
        text-align: center;
      }
    }
  }
}
</style>
