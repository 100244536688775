<template>
  <div class="card">
    <div class="card-body">
      <ul class="nav nav-pills mb-3" id="myTab" role="tablist">
        <li class="nav-item flex-fill" role="presentation" v-for="(menu, index) in arr_menu" :key="index">
          <button class="nav-link w-100" :class="index == selected_tab ? 'active' : ''" :id="menu.id + '-tab'" data-toggle="tab" :data-target="'#' + menu.id" type="button" role="tab" :aria-controls="menu.id" aria-selected="true" @click="onTabClicked(index)">{{ menu.name }}</button>
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div class="tab-pane" v-for="(menu, index) in arr_menu" :key="index" :class="index == selected_tab ? 'active' : ''" :id="menu.id" role="tabpanel" :aria-labelledby="menu.id + '-tab'">
          <component :is="menu.component" :registration_data="registration_data" @onValidation="onValidation" :before_index="before_index" :index="index" :is_submit="is_submit" @onForceSubmit="onForceSubmit" :force_active="false"/>
        </div>
      </div>

      <div class="mt-3 d-flex justify-content-between">
        <button class="btn btn-outline-primary" @click="onCancelClicked">Cancel</button>
        <div>
          <button class="btn btn-primary" v-show="selected_tab > 0" control-id="ControlID-9" @click="onBackClicked">Back</button>
          <button class="btn btn-primary ml-3" v-show="selected_tab < arr_menu.length - 1" control-id="ControlID-10" @click="onNextClicked">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

import ChooseMatch from '@/pages/registration/component/choose_match';
import ChoosePaymentMethod from '@/pages/registration/component/choose_payment_method';

export default {
  components: {
    'ChooseMatch': ChooseMatch,
    'ChoosePaymentMethod': ChoosePaymentMethod,
  },
  data(){
    return{
      base: null,
      arr_menu: [
        {
          id: 'choose_match',
          name: 'Choose Match',
          component: 'ChooseMatch',
        },
        {
          id: 'choose_payment_method',
          name: 'Choose Payment Method',
          component: 'ChoosePaymentMethod',
        },
      ],
      is_submit: false,
      before_index: -1,
      selected_tab: 0,
      registration_data: {},
    }
  },
  watch: {
    registration_data(val){
      if(val.competition != null){
        this.registration_data.competition.start_date_moment = momentTZ.tz(val.competition.start_date, this.base.locale_timezone)
        this.registration_data.competition.end_date_moment = momentTZ.tz(val.competition.end_date, this.base.locale_timezone)
      }
    },
  },
  async mounted(){
    this.base = new Base()

    this.base.save_current_page('Add Match', false, 'mdi mdi-file-document')
    this.get_registration()
  },
  methods: {
    async get_registration(){
      var response = await this.base.request(this.base.url_api + "/event/registration?id=" + this.$route.query.registration_event_id)

      if(response != null){
        if(response.status === "success"){
          window.localStorage.setItem('registration_data', JSON.stringify(response.data))
          // response.data.competition.event.school_registration_moment = momentTZ.tz(response.data.competition.event.school_registration, this.base.locale_timezone)
          // response.data.competition.event.school_registration_moment = momentTZ.tz(response.data.competition.event.school_registration, this.base.locale_timezone)
          this.registration_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    onCancelClicked(){
      this.base.back_page()
    },
    onBackClicked(){
      var selected = this.selected_tab
      this.selected_tab--
      this.before_index = selected
    },
    onNextClicked(){
      var selected = this.selected_tab
      this.selected_tab++
      this.before_index = selected
    },
    onTabClicked(index){
      var selected = this.selected_tab
      this.selected_tab = index
      this.before_index = selected
    },
    onForceSubmit(){
      this.before_index = this.selected_tab
      this.is_submit = true
    },
    async onValidation(message, data){
      if(message != ''){
        this.base.show_error(message)
        this.selected_tab = this.before_index
        this.before_index = -1
      }
      else{
        var registration_data = JSON.parse(JSON.stringify(this.registration_data))
        for(let x in data)
          registration_data[x] = data[x]
        this.registration_data = registration_data

        if(this.before_index == this.selected_tab){
          registration_data.registration_event_id = this.$route.query.registration_event_id
          
          $('#please_wait_modal').modal('show')
          var response = await this.base.request(this.base.url_api + "/event/registration/match", 'post', registration_data)
          $('#please_wait_modal').modal('hide')

          if(response != null){
            if(response.status === "success"){
              window.localStorage.setItem('order_data', JSON.stringify(response.data))
              window.localStorage.setItem('from', 'team')
              window.location.href = "/registration/success"
            }
            else
              this.base.show_error(response.message)
          }
          else
            console.log(this.$t('server_error'))
        }
        else
          window.localStorage.setItem('registration_data', JSON.stringify(registration_data))
      }
    },
  }
}
</script>

<style lang="scss">
</style>
