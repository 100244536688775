<template>
  <div class="" v-if="team_data.id != null">
    <div class="card">
      <div class="card-body mb-0">
        <table class="table" ref="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Photo</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Name</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Position</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Gender</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Back Number</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Birth Date</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action</th>
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
var base = new Base()

export default {
  components: {
  },
  props: ['team_data', 'index', 'selected_tab', 'first_time', ],
  data(){
    return{
      base: null,
      datatable: null,
      force_reload: false,
    }
  },
  watch: {
    selected_tab(val){
      var context = this
      if(val == this.index)
        setTimeout(() => {
          context.init_datatable()
        }, this.first_time ? this.base.wait_datatable_time : 0)
    },
    force_reload(val){
      if(val){
        this.force_reload = false
        this.$emit('onForceReload')
      }
    },
    team_data(val){
      if(val.id != null)
        this.init_datatable()
    },
  },
  mounted(){
    this.base = new Base()

    // setTimeout(() => {
    //   var context = this
    //   $('.remove-player').click(function() {
    //     context.force_reload = context.base.remove_action(context.base.host + '/api/event/registration/player/delete?id=' + $(this).attr('data-id'))
    //   })
    // }, this.first_time ? this.base.wait_datatable_time + 3000 : 3000)
  },
  methods: {
    init_datatable(){
      if(this.team_data.id != null){
        var context = this
        var category = this.team_data.event_category_sport_category
        var total_player = this.team_data.total_player
        var arr_button = []

        if(category.max_player > total_player)
          arr_button.push({
            className: 'btn btn-primary',
            text : "Add",
            action: function ( ) {
              window.location.href = "/team/player/action?registration_event_id=" + context.$route.query.id
            },
            init: function(api, node, config) {
              $(node).removeClass('dt-button')
            },
          })

        context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/event/registration/player?registration_event_id=' + context.$route.query.id, [[0, "asc"]], [
          {"data" : "id",},
          {"data" : "image_format", name: "registration_event_player.file_name", "orderable" : false},
          {"data" : "name", name: "registration_event_player.name"},
          {"data" : "player_position_name", name: "player_position.name"},
          {"data" : "gender_format", name: "registration_event_player.gender"},
          {"data" : "back_number", name: "registration_event_player.back_number"},
          {"data" : "birth_date_format", name: "registration_event_player.birth_date"},
          {"data" : "action", "orderable" : false},
        ], [
          {
            targets: -1,
            data: null,
            sorting: false,
            render: function(data, type, row, meta) {
              var str = ""
              str += '<div style="">'
								str += `<a class="btn btn-primary" target="_blank" href="${context.base.host}/export/suspend?id=${row.id}">Suspend Letter</a>`
                str += `<a class="btn btn-primary ml-3" href="/team/player/action?id=${row.id}">Edit</a>`
                if(category.min_player < total_player)
                  str += `<button class="btn btn-danger ml-3 remove-player" data-id="${row.id}">Delete</button>`
              str += '</div>'
              return str
            },
          },
        ], arr_button, () => {
          setTimeout(() => {
            $('.remove-player').click(function() {
              context.force_reload = context.base.remove_action(context.base.host + '/api/event/registration/player/delete?id=' + $(this).attr('data-id'))
            })
          }, 100)
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
