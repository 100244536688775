<template>
  <div class="card">
    <div class="card-body">
      <h4>Change Password</h4>

      <div class="form-group">
        <label for="oldPassword">Old Password</label>
        <input type="password" v-model="old_password" class="form-control" id="oldPassword">
      </div>

      <div class="form-group">
        <label for="newPassword">New Password</label>
        <input type="password" v-model="new_password" class="form-control" id="newPassword">
      </div>

      <div class="form-group">
        <label for="confirmPassword">Confirm New Password</label>
        <input type="password" v-model="confirm_password" class="form-control" id="confirmPassword">
      </div>

      <div>
        <button class="btn btn-outline-primary" @click="onBackClicked">Back</button>
        <button class="btn btn-primary ml-3" @click="onSubmitClicked">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';

export default {
  components: {
  },
  data() {
    return {
      base: null,
      old_password: '',
      new_password: '',
      confirm_password: '',
    }
  },
  created() {
    this.base = new Base()
    this.base.save_current_page('Change Password', false, 'dripicons-user text-muted')
  },
  methods: {
    onBackClicked(){
      this.base.back_page()
    },
    async onSubmitClicked(){
      if(this.old_password == '')
        this.base.show_error('Old Password is Empty')
      else if(this.new_password == '')
        this.base.show_error('New Password is Empty')
      else if(this.confirm_password == '')
        this.base.show_error('Confirm Password is Empty')
      else if(this.new_password != this.confirm_password)
        this.base.show_error('Confirm Password is not Same with New Password')
      else{
        $('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/auth/change-password", "post", {
          old_password: this.old_password,
          new_password: this.new_password,
        })

        $('#please_wait_modal').modal('hide')
        if(response != null){
          if(response.status === "success"){
            this.base.back_page()
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    },
  }
}
</script>

<style lang="scss">
</style>
