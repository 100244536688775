<template>
  <div>
    <div class="card">
      <div class="card-body">
        <ul class="nav nav-pills mb-3 overflow-auto flex-nowrap" id="myTab" role="tablist">
          <li class="nav-item flex-fill" role="presentation" v-for="(menu, index) in arr_menu" :key="index">
            <button class="nav-link w-100 text-nowrap" :class="index == selected_tab ? 'active' : ''" :id="menu.id + '-tab'" data-toggle="tab" :data-target="'#' + menu.id" type="button" role="tab" :aria-controls="menu.id" aria-selected="true" @click="onTabClicked(index)">{{ menu.name }}</button>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div class="tab-pane" v-for="(menu, index) in arr_menu" :key="index" :class="index == selected_tab ? 'active' : ''" :id="menu.id" role="tabpanel" :aria-labelledby="menu.id + '-tab'">
            <component :is="menu.component" :match_data="match_data" :index="index" :selected_tab="selected_tab" @onForceReload="onForceReload" :first_time="first_time"/>
          </div>
        </div>

        <div class="mt-3 d-flex justify-content-between">
          <button class="btn btn-outline-primary" @click="onCancelClicked">Back</button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import NoImage from '@/assets/no_image.png';

import GeneralInfo from '@/pages/match/component/general_info';
import ListEvent from '@/pages/match/component/list_event';
import ListAttendance from '@/pages/match/component/list_attendance';

export default {
  components: {
    'GeneralInfo': GeneralInfo,
    'ListEvent': ListEvent,
    'ListAttendance': ListAttendance,
  },
  data(){
    return{
      base: null,
      arr_menu: [
        {
          id: 'general_info',
          name: 'General Info',
          component: 'GeneralInfo',
        },
        {
          id: 'list_event',
          name: 'List Event',
          component: 'ListEvent',
        },
        {
          id: 'list_attendance',
          name: 'List Attendance',
          component: 'ListAttendance',
        },
      ],
      selected_tab: 0,
      match_data: {},
      force_reload: false,
      first_time: true,
    }
  },
  watch: {
    match_data(val){
      if(val != null)
        this.first_time = false
    },
    force_reload(val){
      if(val){
        this.force_reload = false
        this.get_registration()
      }
    },
  },
  async mounted(){
    this.base = new Base()

    this.base.save_current_page('Match Detail', false, 'mdi mdi-file-document')
    this.get_registration()

    var tab_index = await window.localStorage.getItem('tab_index')
    if(tab_index != null)
      this.selected_tab = tab_index
  },
  methods: {
    onForceReload(){
      this.force_reload = true
    },
    onTabClicked(index){
      this.selected_tab = index
      window.localStorage.setItem('tab_index', index)
    },
    onCancelClicked(){
      this.base.back_page()
    },
    async get_registration(){
      var response = await this.base.request(this.base.url_api + "/match?id=" + this.$route.query.id)

      if(response != null){
        if(response.status === "success"){
          response.data.date_moment = momentTZ.tz(response.data.date, this.base.locale_timezone)
          response.data.member1.registration_event.url_image = response.data.member1.registration_event.file_name != null ? this.base.host + '/media/registration/team?file_name=' + response.data.member1.registration_event.file_name : NoImage
          response.data.member2.registration_event.url_image = response.data.member2.registration_event.file_name != null ? this.base.host + '/media/registration/team?file_name=' + response.data.member2.registration_event.file_name : NoImage
          this.match_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
</style>
