<template>
  <div class="">
    <CompetitionHeader :competition="registration_data.competition" v-if="registration_data.competition != null"/>
    <div>
      <p class="m-0 mb-3" v-if="registration_data.team_category != null && registration_data.team_category.min_player != null && registration_data.team_category.max_player != null">
				For {{ registration_data.team_category.name }},
				you are suggested to only enter in {{
					registration_data.team_category.min_player != registration_data.team_category.max_player ?
						(registration_data.team_category.min_player.toLocaleString(base.locale_string)+' - '+registration_data.team_category.max_player.toLocaleString(base.locale_string))
					:
						registration_data.team_category.min_player.toLocaleString(base.locale_string)
				}} players max
			</p>

      <div>
        <div v-show="registration_data.team_category != null && registration_data.team_category.max_player > base.max_player_team_name">
          <p class="m-0">Do you want to input Player Registration?</p>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="questionPlayerYes" :value="true" v-model="is_add">
            <label class="form-check-label" for="questionPlayerYes">
              Yes
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="questionPlayerNo" :value="false" v-model="is_add">
            <label class="form-check-label" for="questionPlayerNo">
              No
            </label>
          </div>
        </div>

        <div v-show="registration_data.team_category != null && ((registration_data.team_category.max_player > base.max_player_team_name && is_add) || registration_data.team_category.max_player <= base.max_player_team_name)">
          <div class="row">
            <div class="col-12 col-md-6 mb-5" v-for="(player, index) in arr_player" :key="index">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="card m-0">
                    <div class="card-body">
                      <p class="m-0">{{ index + 1 }}</p>
                    </div>
                  </div>
                  <p class="m-0 ml-3">Player Information</p>
                </div>
                <button class="btn btn-danger" v-show="registration_data.team_category.min_player < index + 1 && registration_data.team_category.max_player >= index + 1" @click="onRemovePlayer(index)">Remove</button>
              </div>
              <table class="w-100">
                <tr>
                  <td><p class="m-0 mb-3">Position</p></td>
                  <td>
                    <v-select @search="onPositionSearch" v-model="player.player_position" :options="arr_position" class="mb-3"/>
                  </td>
                </tr>
                <tr>
                  <td><p class="m-0 mb-3">Player Name</p></td>
                  <td><input type="text" v-model="player.name" class="form-control mb-3"></td>
                </tr>
                <tr>
                  <td><p class="m-0 mb-3">Gender</p></td>
                  <td>
                    <select v-model="player.gender" class="form-control mb-3">
                      <option value="">Select Gender</option>
                      <option value="1">Male</option>
                      <option value="0">Female</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td><p class="m-0 mb-3">Birth Date</p></td>
                  <td><date-picker v-model="player.birth_date" type="date" :default-value="momentNow.toDate()" value-type="format" input-class="form-control mb-3" :format="base.date_format" :disabled-date="disableBirthDate"/></td>
                </tr>
                <tr>
                  <td><p class="m-0 mb-3">Back Number</p></td>
                  <td><input type="text" v-model="player.back_number" class="form-control mb-3"></td>
                </tr>
                <tr>
                  <td><p class="m-0 mb-3">Student ID (NISN)</p></td>
                  <td><input type="text" v-model="player.nisn" class="form-control mb-3"></td>
                </tr>
								<tr>
									<td><p class="m-0 mb-3">Notes</p></td>
									<td><textarea v-model="player.notes" class="form-control mb-3"></textarea></td>
								</tr>
                <tr>
                  <td><p class="m-0 mb-3">Photo</p></td>
                  <td>
                    <CustomImagePicker
                      :image_data1="player.image_data"
                      @onResponse="(image_data) => onImageResponse(image_data, index)"/>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-outline-primary w-100" v-show="registration_data.team_category != null && arr_player.length < registration_data.team_category.max_player" @click="onAddPlayer">Add Player</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import DatePicker from 'vue2-datepicker';
import momentTZ from 'moment-timezone';
import 'vue2-datepicker/index.css';

import CompetitionHeader from '@/pages/registration/component/competition_header';
import CustomImagePicker from '@/layout/custom_imagepicker';

export default {
  components: {
    'CompetitionHeader': CompetitionHeader,
    'CustomImagePicker': CustomImagePicker,
    DatePicker,
  },
  props: ['registration_data', 'before_index', 'index', 'is_submit', 'selected_tab', ],
  data(){
    return{
      base: null,
      arr_position: [],
      arr_player: [],
      momentNow: null,
      is_add: false,
    }
  },
  watch: {
    before_index(val){
      if(val == this.index)
        this.validation()
    },
    registration_data(val){

      if(val.team_category != null){
        this.fill_data()
      }
    },
    selected_tab(val){
      if(val == 1)
        this.onPositionSearch('')
    },
  },
  mounted(){
    this.base = new Base()
    this.momentNow = momentTZ().subtract(this.base.min_age, 'y')

		console.log(this.registration_data)
    this.fill_data()
  },
  methods: {
		fill_data(){
			var arr_player = []
			this.is_add = this.registration_data.arr_player != null && this.registration_data.arr_player.length > 0
			if(this.registration_data.arr_player == null || this.registration_data.arr_player.length == 0){

				for(let x = 0; x < this.registration_data.team_category.min_player; x++)
					arr_player.push({
						name: '',
						gender: 1,
						birth_date: this.momentNow.format(this.base.date_format),
						image_data: {},
						player_position: {},
						back_number: '',
						nisn: '',
						notes: '',
					})
			}
			else{
				for(let player of this.registration_data.arr_player){
					player.player_position.label = player.player_position.name
					player.birth_date = momentTZ.tz(player.birth_date, this.base.locale_timezone).format(this.base.date_format)
				}
				arr_player = this.registration_data.arr_player
			}
			this.arr_player = arr_player
		},
    async onPositionSearch(search){
      var response = await this.base.request(this.base.url_api + "/player-position/all?category_sport_id=" + this.registration_data.competition.category_sport.id + "&search=" + search)

      if(response != null){
        if(response.status === "success"){
          for(let position of response.data){
            position.label = position.name
          }
          this.arr_position = response.data
        }
        // else
        //   this.base.show_error(response.message)
      }
      // else
      //   console.log(this.$t('server_error'))
    },
    disableBirthDate(date){
      return momentTZ(date).isSameOrAfter(momentTZ().subtract(this.base.min_age, 'y'))
    },
    onRemovePlayer(index){
      this.arr_player.splice(index, 1)
    },
    onAddPlayer(){
      this.arr_player.push({
        name: '',
        gender: 1,
        birth_date: '',
        image_data: {},
        player_position: {},
        back_number: '',
        nisn: '',
      })
    },
    onImageResponse(image_data, index){
      var player = this.arr_player[index]
      player.image_data = image_data
      this.$set(this.arr_player, index, player)
    },
    async validation(){
      var message = ""
      if(this.registration_data.team_category != null && ((this.registration_data.team_category.max_player > this.base.max_player_team_name && this.is_add) || this.registration_data.team_category.max_player <= this.base.max_player_team_name)){
        for(let x in this.arr_player){
          if(this.arr_player[x].name == null || this.arr_player[x].name == "")
            message = "Name Player " + (parseFloat(x) + 1) + " is Empty"
          else if(this.arr_player[x].birth_date == null || this.arr_player[x].birth_date == "")
            message = "Birth Date Player " + (parseFloat(x) + 1) + " is Empty"
          // else if(this.arr_player[x].back_number == null || this.arr_player[x].back_number == "")
          //   message = "Back Number Player " + (parseFloat(x) + 1) + " is Empty"
          // else if(this.arr_player[x].nisn == null || this.arr_player[x].nisn == "")
          //   message = "Player ID (NISN) " + (parseFloat(x) + 1) + " is Empty"
          else if(this.arr_player[x].player_position == null || this.arr_player[x].player_position.id == null)
            message = "Player Position Player " + (parseFloat(x) + 1) + " is Empty"
          else if(this.arr_player[x].image_data.image_display == null)
            message = "Image Player " + (parseFloat(x) + 1) + " is Empty"

          if(message != "")
            break;
        }

        if(message == ''){
          $('#please_wait_modal').modal('show')
          var response = await this.base.request(this.base.url_api + "/event/registration/player/edit/array", 'post', {
            arr_player: this.arr_player,
            registration_event_id: this.registration_data.id,
          })
          setTimeout(() => {
            $('#please_wait_modal').modal('hide')
          }, 500)

          if (response != null) {
            if (response.status != "success")
              message = response.message
              // this.base.show_error(response.message)
          }
        }
      }

      if(message == "" && response != null){
        for(let player of response.registration_event.player){
          player.image_data = {
            image_display: player.url_image
          }
        }
      }
      this.$emit('onValidation', message, message == '' ? {
        arr_player: response != null ? response.registration_event.player : [],
      } : {})
    },
  }
}
</script>

<style lang="scss">
</style>
