<template>
  <div class="card">
    <div class="card-body">
      <ul class="nav nav-pills mb-3 overflow-auto flex-nowrap" id="myTab" role="tablist">
        <li class="nav-item flex-fill" role="presentation" v-for="(menu, index) in arr_menu" :key="index">
          <button class="nav-link w-100 text-nowrap" :class="index == selected_tab ? 'active' : ''" :id="menu.id + '-tab'" type="button" role="tab" :aria-controls="menu.id" aria-selected="true">{{ menu.name }}</button>
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div class="tab-pane" v-for="(menu, index) in arr_menu" :key="index" :class="index == selected_tab ? 'active' : ''" :id="menu.id" role="tabpanel" :aria-labelledby="menu.id + '-tab'">
          <component :is="menu.component"
            :registration_data="registration_data"
            @onValidation="onValidation"
            :before_index="before_index"
            :index="index"
            :selected_tab="selected_tab"
            :is_submit="is_submit"
            @onForceSubmit="onForceSubmit"
            @onVisited="onVisited(index)"/>
        </div>
      </div>

      <div class="mt-3 d-flex justify-content-between">
        <button class="btn btn-outline-primary" @click="onCancelClicked">Cancel</button>
        <div>
          <button class="btn btn-primary" v-show="selected_tab > 0" control-id="ControlID-9" @click="onBackClicked">Back</button>
          <button class="btn btn-primary ml-3" v-show="selected_tab < arr_menu.length - 1" control-id="ControlID-10" @click="onNextClicked">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

import TeamRegistration from '@/pages/registration/component/team_registration';
import PlayerRegistration from '@/pages/registration/component/player_registration';
import CoachRegistration from '@/pages/registration/component/coach_registration';
import ChooseMatch from '@/pages/registration/component/choose_match';
import ChoosePaymentMethod from '@/pages/registration/component/choose_payment_method';

export default {
  components: {
    'TeamRegistration': TeamRegistration,
    'PlayerRegistration': PlayerRegistration,
    'CoachRegistration': CoachRegistration,
    'ChooseMatch': ChooseMatch,
    'ChoosePaymentMethod': ChoosePaymentMethod,
  },
  data(){
    return{
      base: null,
      arr_menu: [],
      arr_already_visited: [],
      is_submit: false,
      before_index: -1,
      selected_tab: 0,
      registration_data: {},
    }
  },
  watch: {
    registration_data(val){
      if(val.competition != null){
        this.registration_data.competition.start_date_moment = momentTZ.tz(val.competition.start_date, this.base.locale_timezone)
        this.registration_data.competition.end_date_moment = momentTZ.tz(val.competition.end_date, this.base.locale_timezone)
      }
    },
    // arr_menu(val){
    //   var arr_already_visited = []
    //   for(let menu of val)
    //     arr_already_visited.push(false)
    //   this.arr_already_visited = arr_already_visited
    // },
    selected_tab(val){
      this.$set(this.arr_already_visited, val, true)
    },
  },
  async created(){
    this.base = new Base()

    this.base.save_current_page('Registration', false, 'mdi mdi-file-document')
    var registration_data = await window.localStorage.getItem('registration_data')
		registration_data = JSON.parse(registration_data)
    this.registration_data = registration_data

		var arr_menu = []
		arr_menu.push(
			{
				id: 'team_registration',
				name: 'Team Registration',
				component: 'TeamRegistration',
			},
			{
				id: 'player_registration',
				name: 'Player Registration',
				component: 'PlayerRegistration',
			},
			{
				id: 'coach_registration',
				name: 'Coach Registration',
				component: 'CoachRegistration',
			},
		)
		if(registration_data.competition.scoring_type.data == 'league' || registration_data.competition.scoring_type.data == 'tournament')
			arr_menu.push(
				{
					id: 'choose_match',
					name: 'Choose Match',
					component: 'ChooseMatch',
				},
			)
		arr_menu.push(
			{
				id: 'choose_payment_method',
				name: 'Choose Payment Method',
				component: 'ChoosePaymentMethod',
			},
		)
		this.arr_menu = arr_menu

    var arr_already_visited = []
    for(let menu of arr_menu)
      arr_already_visited.push(false)
    arr_already_visited[0] = true

    this.arr_already_visited = arr_already_visited
  },
  methods: {
    onVisited(index){
      this.$set(this.arr_already_visited, index, true)
    },
    onCancelClicked(){
      this.base.back_page()
    },
    onBackClicked(){
      var selected = this.selected_tab
      this.selected_tab--
      this.before_index = selected
    },
    onNextClicked(){
      var selected = this.selected_tab
      this.selected_tab++
      this.before_index = selected
    },
    onTabClicked(index){
      // var selected = this.selected_tab
      // this.selected_tab = index
      // this.before_index = selected
    },
    onForceSubmit(){
      this.before_index = this.selected_tab
      this.is_submit = true
    },
    async onValidation(message, data){
      if(message != ''){
        this.base.show_error(message)
        this.selected_tab = this.before_index
        this.before_index = -1
      }
      else{
        var registration_data = JSON.parse(JSON.stringify(this.registration_data))
        for(let x in data)
          registration_data[x] = data[x]
        this.registration_data = registration_data
        await window.localStorage.setItem('registration_data', JSON.stringify(registration_data))

        if(this.before_index == this.selected_tab){
          var arr_already_visited = JSON.parse(JSON.stringify(this.arr_already_visited))
          var flag = false
          var index = -1
          for(let x in arr_already_visited){
            if(!arr_already_visited[x]){
              flag = true
              index = x
              break
            }
          }

          if(flag){
            this.base.show_error('You are not visited tab no ' + (parseFloat(index) + 1) + " before submit\nYou must visited all tab first before submit data")

            if(data.id != null){
              var response = await this.base.request(this.base.url_api + "/event/registration/delete/force?id=" + data.id)
              if(response != null){
                if(response.status != "success")
                  this.base.show_error(response.message)
              }
            }
          }
          else{
            $('#please_wait_modal').modal('show')
            response = await this.base.request(this.base.url_api + "/order/registration", 'post', registration_data)
            setTimeout(() => {
              $('#please_wait_modal').modal('hide')
            }, 500)

            this.is_submit = false
            if(response != null){
              if(response.status === "success"){
                window.localStorage.setItem('order_data', JSON.stringify(response.data))
                window.localStorage.setItem('from', 'registration')
                window.location.href = "/registration/success"
              }
              else
                this.base.show_error(response.message)
            }
            else
              console.log(this.$t('server_error'))
          }
        }
        else
          window.localStorage.setItem('registration_data', JSON.stringify(registration_data))
      }
    }
  }
}
</script>

<style lang="scss">
</style>
