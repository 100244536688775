<template>
  <div class="page-wrapper-img">
      <div class="page-wrapper-img-inner">
          <div class="sidebar-user media d-none d-lg-flex" style="max-width: 270px">
              <img :src="profile_data.url_image" alt="user" class="rounded-circle img-thumbnail mb-1" style="aspect-ratio: 1 /1 ; object-fit: cover; object-position: center;">
              <span class="online-icon"><i class="mdi mdi-record text-success"></i></span>
              <div class="media-body">
                  <h5 class="text-light text-left">{{ profile_data.name }}</h5>
                  <!-- <ul class="list-unstyled list-inline mb-0 mt-2">
                      <li class="list-inline-item">
                          <a href="javascript: void(0);" class=""><i class="mdi mdi-account text-light"></i></a>
                      </li>
                      <li class="list-inline-item">
                          <a href="javascript: void(0);" class=""><i class="mdi mdi-settings text-light"></i></a>
                      </li>
                      <li class="list-inline-item">
                          <a href="javascript: void(0);" class=""><i class="mdi mdi-power text-danger"></i></a>
                      </li>
                  </ul> -->
              </div>
          </div>
          <!-- Page-Title -->
          <div class="row">
              <div class="col-sm-12">
                  <div class="page-title-box">
                      <h4 class="page-title mb-2"><i class="mr-2" :class="current_pages.icon"></i>{{ current_pages.name }}</h4>
                      <div class="">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item" :class="index == items.length - 1 ? 'active' : ''" v-for="(item, index) in items" :key="index">
                                <span v-if="index == items.length - 1">
                                  {{ item.text }}
                                </span>
                                <span v-else>
                                  <a href="#" @click="onClicked(index)">{{ item.text }}</a>
                                </span>
                              </li>
                          </ol>
                      </div>
                  </div>
              </div>
          </div>
          <!-- end page title end breadcrumb -->
      </div>
  </div>
</template>

<script>
import Base from '@/utils/base'

import NoImage from '@/assets/no_image.png';

export default {
  props: ['scrollY', 'profile_data',],
  data() {
    return {
      base: null,
      items: [],
      current_pages: {},
    }
  },
  async created() {
    this.base = new Base()

    setTimeout(() => {
      var arr_pages = window.localStorage.getItem('arr_pages')
      arr_pages = JSON.parse(arr_pages)
      this.current_pages = arr_pages[arr_pages.length - 1]
      this.get_pages()
    }, 500)
  },
  methods: {
    async get_pages() {
      var arr_pages = await window.localStorage.getItem('arr_pages')
      arr_pages = JSON.parse(arr_pages)

      var arr = []
      for(let x in arr_pages){
        arr.push({
          text: arr_pages[x].name,
          href: arr_pages[x].url,
          // active: x == arr_pages.length - 2,
        })
      }
      this.items = arr
    },
    onClicked(index){
      // console.log(index)
      if(index < this.items.length - 1)
        this.base.back_page(true, index)
    },
  }
}
</script>

<style lang="scss">
  .page-wrapper-img{
    background-image: url('@/assets/topbar_image.png');
    background-size: cover;
    background-position: left bottom;
    background-repeat: no-repeat;
    box-shadow: none;
  }
</style>
