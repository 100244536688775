<template>
  <div class="modal fade" id="addCoachModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Coach</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="w-100">
            <tr>
              <td><p class="m-0 mb-3">Position</p></td>
              <td>
                <v-select @search="onPositionSearch" v-model="selected_position" :options="arr_position"/>
              </td>
            </tr>
            <tr>
              <td><p class="m-0 mb-3">{{ $t('name') }}</p></td>
              <td>
                <input type="text" v-model="name" class="form-control">
              </td>
            </tr>
            <tr>
              <td><p class="m-0 mb-3">Gender</p></td>
              <td>
                <select v-model="gender" class="form-control mb-3">
                  <option value="">Select Gender</option>
                  <option value="1">Male</option>
                  <option value="0">Female</option>
                </select>
              </td>
            </tr>
            <tr>
              <td><p class="m-0 mb-3">Birth Date</p></td>
              <td><date-picker v-model="birth_date" type="date" :default-value="momentNow.toDate()" value-type="format" input-class="form-control mb-3" :format="base.date_format" :disabled-date="disableBirthDate"/></td>
            </tr>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary w-100" @click="onSubmit">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import DatePicker from 'vue2-datepicker';
import momentTZ from 'moment-timezone';
import 'vue2-datepicker/index.css';
// import moment from 'moment';

export default {
  components: {
    DatePicker,
  },
  props: ['coach_data', ],
  data() {
    return {
      base: null,
      arr_position: [],
      selected_position: {},
      id: '',
      name: '',
      gender: 1,
      birth_date: '',
      momentNow: null,
    }
  },
  watch: {
    coach_data(val){
      this.id = val.id != null ? val.id : ''
      this.name = val.id != null ? val.name : ''
      this.gender = val.id != null ? val.gender : 1
      this.birth_date = val.id != null ? val.birth_date : ''
      this.selected_position = val.id != null ? val.coach_position : {}
    },
  },
  created() {
    this.base = new Base()
    this.momentNow = momentTZ().subtract(this.base.min_age, 'y')

    this.onPositionSearch('')
  },
  methods: {
    disableBirthDate(date){
      return momentTZ(date).isSameOrAfter(momentTZ().subtract(this.base.min_age, 'y'))
    },
    async onPositionSearch(search){
      var response = await this.base.request(this.base.url_api + "/coach-position/all?search=" + search)

      if(response != null){
        if(response.status === "success"){
          for(let position of response.data){
            position.label = position.name
          }
          this.arr_position = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async onSubmit(){
      if(this.selected_position.id == null)
        this.base.show_error('Position not Selected yet')
      else if(this.name == "")
        this.base.show_error('Name is Empty')
      else if(this.birth_date == "")
        this.base.show_error('Birth Date is Empty')
      else{
        var url = this.base.url_api + "/event/registration/coach"
        var method = "post"
        var data = {}

        data = {
          coach_position: this.selected_position,
          registration_event_id: this.$route.query.id,
          name: this.name,
          gender: this.gender,
          birth_date: momentTZ(this.birth_date, this.base.date_format).format('YYYY-MM-DD'),
        }

        if(this.id != ''){
          data.id = this.id
          url += "/edit"
          method = 'put'
        }

        $('#please_wait_modal').modal('show')
        var response = await this.base.request(url, method, data)

        $('#please_wait_modal').modal('hide')
        if(response != null){
          if(response.status === "success"){
            window.location.reload()
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    },
  }
}
</script>

<style lang="scss">
</style>
